/* department SLIDER */
.department-slider{
    position: relative;
    overflow: hidden;
}
.department-slider:after{
    content: '';
    position: absolute;
    top: -50%;
    left: 50%;
    width: 60vw;
    height: 200%;
    background-color: var(--bg-yellow);
    z-index: -1;
    transform: rotate(20deg);
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.department-slider__slide{
    width: 330px !important;
    height: 330px;
    @media screen and (max-width: 767px) {
        width: 185px !important;
        height: 185px;
    }
}

.department-slider .slick-slide{
    margin-right: 30px
}
.department-slider .slick-list{
    padding-top: 30px;
    height: 450px;
    @media screen and (max-width: 767px) {
        padding-top: 10px;
        height: 260px;
    }
}

.department-card{
    position: relative;
    overflow: visible;
    transition-duration: .33s;
    transition-property: transform;
}
.department-card:after{
    transition-duration: .33s;
    transition-property: filter;
}
.department-card:hover:after{
    filter: contrast(1.5) brightness(1.25);
}
.department-card:hover, .department-card:focus{
    transform: perspective(1px) scale(1.05);
}
.department-card:hover .department-card__shadow:before, .department-card:focus .department-card__shadow:before{
    opacity: 0.5;
    transform: translateY(25%) scale(1.1)
}
.department-card__shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
.department-card__shadow:before{
    content: '';
    position: absolute;
    bottom: 10px;
    left: 10%;
    width: 80%;
    height: 10%;
    opacity: 1;
    box-shadow: 0 20px 50px 0 rgba(0,0,0,.75);
    transition-duration: .33s;
    transition-property: opacity, transform;
}

.department-card__title{
    -webkit-font-smoothing: antialiased;
    font-family: var(--font-default-heading-thin);
    z-index: 1;
    position: absolute;
    margin: 0;
    bottom: 7%;
    left: 7%;
}