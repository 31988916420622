#iziQuestion .iziToast-body{
    display: flex;
    flex-direction: column;
}

#iziQuestion .iziToast-message{
    font-size: 1.5em;
}
#iziQuestion .iziToast-title{
    font-size: 1.5em;
}
#iziQuestion .iziToast-buttons{
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
}
#iziQuestion .iziToast-buttons-child{
    border-radius: 0;
    font-size: 1.3em;
}