.product-grid{
  position: relative;
}
.product-grid__loading{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.product-grid__loading:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: 15px;
  height: 100%;
  background-color: #fff;
  opacity: .65;
}


.productgrid-collapse .icon{
  transition-duration: .33s;
  transform: rotate(0);
  font-size: .5em;
  width: 13px;
  height: 8px;
  margin-bottom: 2px;
  transition-property: transform;
  transform-origin: center;
}
.productgrid-collapse:not(.collapsed) .icon{
  transform: rotate(180deg);
}

.productgrid-item{
  position: relative;
}

.card--productgrid{
  display: flex;
  position: relative;
  border: none;
  justify-content: space-between;
}
.card--productgrid:after{
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  transition-duration: .33s;
  transition-property: opacity;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
  z-index: -1;
  pointer-events: none;
}
.card--productgrid:hover:after{
  opacity: 1;
}
/* for the cardview */
.productgrid-items .card--productgrid{
  flex-direction: column;
}
.productgrid-items--list .card--productgrid{
  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
}
.productgrid-items--list .card-image {
  @media screen and (min-width: 1200px) {
    flex:  0 0 17%;
    max-width:  17%;
  }
}
.productgrid-items .green-label {
    position: absolute;
    width: 20%;
    max-width: 80px;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%);
}
.productgrid-items--list .card-block {
  @media screen and (min-width: 1200px) {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    flex: 1;
    max-width: 25%;
  }
}
.card-block.card-block--end{
  justify-content: flex-end;
  flex-direction: column;
  flex: 1 0 0px;
  flex-basis: auto;
}
.productgrid-items--list .card-block.card-block--center {
  @media screen and (min-width: 1200px) {
    flex: 1;
    max-width: 100%;
  }
}
.productgrid-items--list .card-block.card-block--end {
  @media screen and (min-width: 1200px) {

    flex: 1;
    min-width: 175px;
    max-width: unset;
  }
}
/* for the cardview */
.productgrid-items .productgrid-item{
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media screen and (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.productgrid-items--list .productgrid-item{
  @media screen and (min-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.card--productgrid select{
  padding-top: 0;
  padding-bottom: 0;
  height: 1.75rem;
}

.card--productgrid__subtitle{
  margin-bottom: 0;
  font-size: var(--small);
  white-space: nowrap;
  max-width: 40%;
}

.card-block.card-block--center .dropdown.bootstrap-select.js-product-gridcard__ajax-input{
    max-width: 60%;
}

.productgrid-header__background{
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.productgrid-navigation{
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}
.productgrid-navigation .page-link{
  border: none;
  color: var(--color-text-default);
  background-color: transparent;
}
.productgrid-navigation .page-item.active .page-link{
  border: none;
    color: #fff;
  background-color: var(--color-primary);
}
.productgrid-navigation .icon{
  font-size: .5em;
  margin: 10px;
}

.page-item .page-link{
  white-space: nowrap;
}




/* SIDEBAR MOBILE */
.product-grid__form-container{
  @media screen and (max-width: 767px) {
    background: #fff;
    position: fixed;
    overflow-y: auto;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0;
    transform: translateX(-110%);
    transition-duration: .33s;
    transition-property: transform;
    z-index: 1000;
  }
}

.product-grid__form-title{
  display: none;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    padding-left: 15px;
  }
}

.product-grid__form-container .product-grid__form{
  @media screen and (max-width: 767px) {
    overflow-y: scroll;
    height: calc(100% - 60px);
  }
}

.product-grid__form-container.open{
  transform: translateX(0)
}

.sidebar-container .form-group .select-custom__icon {
  height: 44px;
}