.btn{
  font-family: var(--font-default-heading-thin);
}

.btn-unstyled{
  box-shadow: none !important;
  background: transparent;
  border:none;
}
.btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}


.btn.btn-outline-secondary{
  border-color: white;
  color: white;
}
.btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:focus,.btn.btn-outline-secondary:active {
  color: var(--color-text-default);
  background: #fff;
}
.btn.btn-outline-black{
  border-color: #000;
  color: #000;
}
.btn.btn-outline-black:hover, .btn.btn-outline-black:focus,.btn.btn-outline-black:active {
  color: #fff;
  background: #000;
}
.hero-slider__button{
  padding: 12px 50px;
}

.btn.btn-secondary{
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
}
.btn.btn-primary{
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.2);
}

.btn.btn-floating-quad{
  width: 56px;
  height: 56px;
}
.btn.btn-yellow{
  background-color: var(--bg-yellow);
}

.btn-black-outline{
  background-color: transparent;
  background-image: none;
  border-color: #000;
  color: #000;
}

.btn-black-outline:hover, .btn-black-outline.active{
  color: #fff;
  background-color: #000;
}



.btn-icon{
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-gallery{
  position: absolute;
}

.btn-quad{
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-grey{
  background-color: var(--color-grey);
  color: #fff;
}

.btn-play, .btn-gallery{
  position: absolute;
  width: 60px;
  height: 60px;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  background-color: transparent;
  z-index: 1;
}

.btn-gallery {
  opacity: 0;
  transform: translateY(-25%);
  transition-duration: .5s;
  transition-property: transform, opacity;
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.lightbox-item:hover .btn-gallery {
  opacity: 1;
  transform: translateY(0);
}

.lightbox-item:hover .lightbox-open {
  cursor: pointer;
  opacity: 1;
  transform: translateY(0);
}

.btn-xl{
  padding: .95em .75rem;
}
.btn-xxl{
  height: 56px;
  min-width: 56px;
}




/*@keyframes wobble {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}*/
@keyframes wobble {
  16.65% {
    transform: scale(1);
  }
  66.6% {
    transform: scale(1.3);
  }
  83.25% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.6);
  }
}


.btn-wizard{
  z-index: 2;
  width: 35px;
  height: 35px;
  margin-top: -17px;
  margin-left: -17px;
  border-radius: 100%;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.btn-wizard.active{
  color: #fff;
}
.btn-wizard.active:before{
  background-color: var(--color-primary);
}
/*.btn-wizard.btn-wizard--primary:before{
  border: 2px solid var(--color-primary);
}*/
.btn-wizard:after{
  z-index: -1;
  position: absolute;
  top: 3.77px;
  left: 3.77px;
  width: 25px;
  height: 25px;
  content: '';
  opacity: .6;
  background-color: var(--bg-yellow);
  border-radius: 100%;
}
.btn-wizard:before{
  z-index: 0;
  position: absolute;
  top: 3.77px;
  left: 3.77px;
  width: 25px;
  height: 25px;
  content: '';
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.2);
  transition-property: background-color;
  transition-duration: .33s;
}
.btn-wizard:hover:after{
  animation: wobble 0.5s forwards;
}
.btn-wizard.active:after{
  animation: none ;
  transform: scale(1.6);
  opacity: 1;
}


.btn-select{
  color: #999;
  padding: .25rem .75rem;
  font-family: var(--font-default);
  box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 0 1px 1px rgba(0,0,0,.075);
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.btn-select:after{
  content: url('/static/img/icons/arrow-small.svg');
  width: 10px;
  height: 24px;
  position: absolute;
  right: 10px;
}
.btn-select--selected{
  color: #000;
}