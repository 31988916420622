thead{
    background-color: var(--bg-primary);
    color: white;
}

.product-detail-table{
    border: 1px solid #dee2e6;
    font-size: calc(14rem/16);
}

/*.product-detail-table p{
    margin: 0;
}*/

.product-detail-table td{
    border-right: 1px solid #f3f3f3;
}

.product-detail-table__header{
    background-color: #F3F3F3;
}

.product-detail-table__colWidth{
    width: 33%;
}

.table td, .table th{
    padding: 1.25rem;
    @media screen and (max-width: 767px) {
        padding: .75rem;
    }
}

.table--user .icon{
    transition-duration: .33s;
    transition-property: color;
}
.table--user tr.js-go-to-link:hover .icon-edit{
    color: var(--color-primary)
}
.table--user__icons{
    display: flex;
    flex-direction: row-reverse;
}
.table--user__icons .table--user__delete:hover ~ a .icon-edit{
    color: var(--color-text-default);
}
.table--user__icons .table--user__delete:hover .icon-delete{
    color: var(--color-primary)
}


.table--responsive thead{
    @media screen and (max-width: 767px) {
        display: none;
    }
}
.table--responsive tr{
    @media screen and (max-width: 767px) {
        border-bottom: 1px solid #c2c2c2;
    }
}
.table--responsive td, .table--responsive th{
    @media screen and (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        border: none;
        padding: 3px 15px;
        text-align: right;
        width: auto;
    }
}
.table--responsive tr>td:first-child{
    @media screen and (max-width: 767px) {
        padding-top: 15px;
    }
}


.table--checkout th{
    padding: 0.33rem 0.25rem;
    vertical-align: middle;
}
.table--checkout td{
    padding: 0.75rem 0.25rem;
    vertical-align: middle;
}
.table--checkout__total{
    border-top: 2px solid var(--color-grey)
}
.table--checkout__total td {
    vertical-align: top;
}
.table--checkout__total-value{
    font-size: 20px;
}
.table--checkout__download {
    width: 2rem;
}

.table--checkout__download img {
    width: 2rem;
}


.table--yellow th{
    padding: 0.33rem 0.25rem;
    vertical-align: middle;
    background-color: var(--bg-yellow);
    color: #fff;
}
.table--yellow td{
    padding: 0.75rem 0.25rem;
    vertical-align: middle;
}

.table--yellow thead th:first-child{
    @media screen and (min-width: 768px) {
        padding-left: 10px;
    }
}
.table--yellow tbody td:first-child{
    @media screen and (min-width: 768px) {
        padding-left: 13px;
    }
}