.nav-tabs--product-detail{
  display: flex;
  justify-content: center;
  border-bottom: none;
  margin-bottom: 20px;
}

.nav-tabs--product-detail .nav-link{
  border: none;
  text-transform: uppercase;
  font-family: var(--font-default-heading);
  font-size: .8em;
}
.nav-tabs--product-detail .nav-link.active{
  border-bottom: 5px solid var(--bg-yellow);
}


.nav-tabs--default{
  display: flex;
}
.nav-tabs--default .nav-link{
  border: none;
  text-transform: uppercase;
  font-family: var(--font-default-heading);
  font-size: .8em;
}
.nav-tabs--default .nav-link.active{
  border-bottom: 5px solid var(--color-primary);
}


.nav-tabs--big .nav-link{
  font-size: 1.4em;
  @media screen and (max-width: 767px){
    font-size: 1.1em;
  }
}