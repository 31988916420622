.vjs-default-skin .vjs-big-play-button{
  /*width: 100px;
  height: 72px;*/
  border-radius: 50%;

  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  background-color: transparent;
  /*background: transparent;*/
  border: none;
  width: auto;
  height: auto;
}

/*.video-js .vjs-big-play-button .vjs-icon-placeholder:before, .video-js .vjs-play-control .vjs-icon-placeholder:before, .vjs-icon-play:before{
  content: "\EA20" !important;
  font-family: icon-font;
  color: white;
}*/

.vjs-default-skin .vjs-big-play-button:hover,
.vjs-default-skin .vjs-big-play-button:focus,
.vjs-default-skin .vjs-big-play-button:active{
  background-color: transparent;
}

.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin:focus .vjs-big-play-button,
.vjs-default-skin:active .vjs-big-play-button{
  background-color: transparent;
}

.video-js{
  /*background: rgba(0, 0, 0, 0.5);*/
  /*background-color: white;*/
}

.video-js.vjs-fullscreen{
  background-color: black;
}

/*.vjs-tech { object-fit: cover; } */
.vjs-tech{
  height: 100%;
  width: auto;
}

/*
.vjs-default-skin{
  display: none !important;
}*/



.video-js .icon.icon-play-button{
  font-size: 6rem;
}
.vjs-has-started.vjs-fullscreen .vjs-control-bar{
  display: flex;
}


.video-wrapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}
.video-wrapper .video-js{
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%)
}

.video-wrapper .video-js:not(.vjs-has-started){
  width: 100%;
}

.video-text{
  position: absolute;
  top: 28px;
  color: white;
}


.js-video-js--autoplay.vjs-has-started .vjs-control-bar{
  display: none;
}
/*
.vjs-poster{
  background-size: inherit;
}*/

.vjs-default-skin:not(.vjs-controls-enabled){
    visibility: hidden;
}
.vjs-default-skin.vjs-controls-enabled {
    visibility: visible;
}
.vjs-adler-skin .vjs-time-tooltip{
    border-radius: 0;
}