/* Important: This file is using by two areabrick:
    + heroSlider
    + heroSliderWithoutTiles
 */

.heroSlider-cards--container{
    position: absolute;
    bottom: -20px;
    left: 0;
    min-height: 220px;
    z-index: 1;
}

.heroSlider-cards--alignment{
    min-height: 220px;
}

.heroSlider-loader-mobile{
    position: absolute;
    bottom: 20px;
    right: 0;
}

.hero-slider{
    position: relative;
    height: var(--hero-slider--height);
}

@media screen and (max-width: 767px) {
    .hero-slider {
        height: var(--hero-slider--height--mobile);
        margin-bottom: auto;
        display: contents;
    }

    .hero-slider__img-wrapper {
        height: var(--hero-slider--height--mobile);
    }

    .heroSlider-cards--container {
        bottom: auto;
        position: fixed !important;
    }

    .heroSlider-loader-mobile__withoutTiles {
        top: 270px;
        bottom: unset;
    }

    .heroSlider-loader {
        display: none;
    }
}

.hero-slider .slide {
    display: flex !important;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    margin: 0;
    position: relative;
}

.hero-slider__img-wrapper {
    height: var(--hero-slider--height);
}

.hero-slider__img-wrapper--video {
    /*background-color: #000;*/
    width: 100%;
    display: flex;
    justify-content: center;

}
.hero-slider__video .vjs-tech{
    object-fit: cover;
    /*
    height: auto !important;
    width: 100% !important;
    top: -100px !important;
    */
}
/*
.hero-slider__img-wrapper--videoMobile {
    display: none;

    @media screen and (max-width: 767px) {
        display: block;
    }
}
*/

.hero-slider__video {
    height: 610px;
    width: 1920px;

    @media screen and (max-width: 767px) {
        height: 320px;
        width: auto;
    }
}

.hero-slider__img {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    width: 100%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 1700px) {
        height: 100%;
        width: auto;
    }

    @media screen and (min-width: 1701px) {
        .hero-slider__img {
            height: auto;
            width: auto;
        }
    }
}


.hero-slider__img--height{
    height: 100%;
    width: auto;
}.hero-slider__img--height img{
     height: 100%;
     width: auto;
 }
@keyframes hero-slider__animation {
    from {
        stroke-dashoffset: 250;
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes hero-slider__animation--mobile {
    from {
        stroke-dashoffset: 156;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.hero-slider__svg-circle{
    position: relative;
}

.hero-slider__nextclick {
    cursor: pointer;
}

.hero-slider__progress {
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    animation: hero-slider__animation 10s linear;
    animation-play-state: paused;

    @media all and (-ms-high-contrast: none) {
        display: none;
    }

    @media screen and (max-width: 767px) {
        stroke-dasharray: 156;
        stroke-dashoffset: 156;
        animation: hero-slider__animation--mobile 10s linear;
        animation-play-state: paused;
    }
}

.slider-progress {
    width: 100%;
    height: 3px;
    background: #eee;
}

.slider-progress .progress {
    width: 0;
    height: 3px;
    background: #000;
}

.hero-slider__svg-circle__content {
    pointer-events: none;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 38px;
    left: 44px;
    color: white;

    &.icon-arrow-left {
        transform: none;
        padding: 0;
    }
}

.hero-slider__svg-circle__content:before {
    font-size: 25px;
}

.hero-slider__slide-content {
    z-index: 1;
    color: white;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 767px) {
        .h1 {
            margin-bottom: 16px;
        }
    }
}

.hero-slider__slide-content-textarea {
    padding-bottom: 200px;

    @media screen and (max-width: 767px) {
        padding-bottom: 0;
    }
}

.hero-slider__hero-card {
    cursor: pointer;
    position: relative;
    height: 180px;
    width: 340px;
}

.hero-slider__hero-card:before {
    z-index: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.65;
    transition: 0.4s linear all;
    background-color: var(--bg-primary);
}

.hero-slider__hero-card.active:before {
    opacity: 1;
}

@media screen and (min-width: 768px) {
    .hero-slider__hero-card.active:before {
        transform: scaleY(1.3);
    }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
    .hero-slider__hero-card {
        width: 270px;
    }
}

@media screen and (max-width: 767px) {
    .heroSlider-cards--container {
        position: relative !important;
        display: none !important;
    }

    .hero-slider__hero-card,
    .hero-slider__hero-card.active {
        width: 100%;
        height: 60px;
    }
}