.slick-slider > div + div  {
    visibility: hidden;
}
.slick-slider:not(slick-initialized) .slick-track > div + div  {
    visibility: visible;
}


.slider__arrow{
    background: none;
    padding: 0;
    width: 75px;
    height: 75px;
    z-index: 2;
    color: #313131;
    cursor: pointer;
    font-size: 21px;
    border: 1px solid #313131;
    border-radius: 50%;
    line-height: 1;
    transition: all .15s ease;
    background: #fff;
    transition-property: background-color, color, border;
}

.slider__arrow.slider__arrow--white{
    border-color: #fff;
    color: #fff;
    transition-duration: .33s;
    background-color: transparent;
}
.slider__arrow.slider__arrow--white:hover{
    background-color: rgba(255,255,255,.25);
}
.slider__arrow.slider__arrow--white:focus{
    border-width: 2px;
    background-color: rgba(255,255,255,.25);
}

.slider__arrow:focus {
    outline: none;
    border-width: 2px;
}

.slider__arrow.slick-next{
    right: 0;
}
.slider__arrow .icon{
    transform: translateX(10%) translateY(10%)
}

.slider__arrow.slick-prev{
    left: 0;
}

.slider__arrow.slick-prev .icon{
    transform: rotate(180deg);
}

.text-img-slider__btns{
    transform: translateY(-100%);
    @media screen and (max-width: 767px) {
        transform: translateY(0);
    }
}

.slick-disabled{
    opacity: 0.3;
}

.current-slide-element{
    color: white;
    font-size: 20px;
    letter-spacing: 2px;
  white-space: nowrap;
}

.svg-circle{
    position: relative;
}



:root {
    --hero-slider--height: 540px;
    --hero-slider--height--mobile: 320px;
}

/*-----------------imgTextSlider-----------------*/
.text-img-slider__counter{
  letter-spacing: 2px;
}

.text-img-slider__mobile-img{
    @media screen and (max-width: 767px){
        margin-top: -20px;
    }
}

.text-img-slider__mobile-text{
    @media screen and (max-width: 767px){
        padding: 30px;
    }
}

.text-img-slider__fz{
    font-size: 24px;
    @media screen and (max-width: 1200px){
        font-size: 18px;
    }
}


.halfCircle{
  height: 100px;
  width: 50px;
  background-color: var(--bg-yellow);
  font-family: "iconfont";
  position: relative;
  cursor: pointer;
}

.halfCircle:hover{
  background-color: var(--bg-yellow-hover);
}
.halfCircle:active{
  background-color: var(--bg-yellow-hover);
}

.halfCircle:before{
  box-sizing: border-box;
  content: url("/static/img/icons/arrow-big.svg");
  position: absolute;
  color: white;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0px;
}
.halfCircle--left:before{
  transform: rotate(180deg) translateY(50%);
  left: calc(50% - 15px);
}
.halfCircle--right:before{
  left: calc(50%);
}

.halfCircle--right{
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}

.halfCircle--left{
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}

.text-img-slider-halfCircle-right{
  position: absolute;
  top: 50%;
  right: 0;
}
.text-img-slider-halfCircle-left{
  position: absolute;
  top: 50%;
  left: 0;
}