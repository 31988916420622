/* Show mobile cards on mobile view */
@media screen and (max-width: 767px) {
    .department-slider__mobile-cards {
        display: block;
        padding: 0 12px;
    }
    .department-slider-mobile__slide {
        width: calc(50% - 6px);
        margin-bottom: 12px;
        float: left;
        display: none; /* Hide all by default, JS will show first 6 */
    }
    .department-slider-mobile__slide:nth-child(2n) {
        margin-right: 0;
    }
    .department-slider-mobile__slide:nth-child(2n+1) {
        margin-right: 12px;
    }

    /* Button Styles */
    .load-more-container {
        clear: both;
        display: flex;
    }

    .toggle-load-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        padding: 0px 50px;

        span {
            color: var(--primary, #D8222B);
            margin-right: 5px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: -0.48px;
        }

        .toggle-arrow {
            transition: transform 0.3s;
        }

        .toggle-load.show-less + .toggle-arrow {
            transform: rotate(180deg);
        }

        .toggle-load {
            cursor: pointer;
        }
    }

    .toggle-load-container__disabled {
        display: none;
    }

    .btn-primary .toggle-load i {
        transform: rotate(0deg); /* Down arrow */
    }

    .btn-secondary .toggle-load i {
        transform: rotate(180deg); /* Up arrow */
    }
    .department-card-optimized-mobile {
        opacity: 0.8;
        background: var(--bg-yellow, linear-gradient(227deg, #F9C623 12.69%, #D8222B 100%));
    }
}

/* department SLIDER */
.department-slider-optimized {
    position: relative;
    overflow: hidden;
}

.department-slider-optimized .slick-track {
    margin-left: 40px !important;
}

.department-slider-optimized:after {
    content: '';
    position: absolute;
    top: -50%;
    left: 50%;
    width: 60vw;
    height: 200%;
    background-color: var(--bg-yellow);
    z-index: -1;
    transform: rotate(20deg);
}

.department-slider-optimized:after {
    background-color: unset !important;
}

@media screen and (max-width: 767px) {
    .department-slider-optimized:after {
        display: none !important;
    }
}

.department-slider-optimized__slide {
    width: 330px !important;
    height: 330px;
}

.department-slider-optimized .slick-slide {
    margin-right: 30px;
}

.department-slider-optimized .slick-list {
    padding-top: 30px;
    height: 435px;
    margin: 0px 20px !important;
}

@media screen and (max-width: 767px) {
    .department-slider-optimized__slider {
        padding: 0 24px !important;
    }
    .department-slider-optimized__container {
        padding: 32px 0;
        text-align: center !important;
    }
    .department-slider-optimized .slick-list {
        padding-top: 10px;
        height: 260px;
    }
    .department-slider-loader {
        display: none !important;
    }
}

/* Department Card Styles */
.department-card-optimized {
    position: relative;
    overflow: visible;
    transition-duration: .33s;
    transition-property: transform;
}

.department-card-optimized:after {
    transition-duration: .33s;
    transition-property: filter;
}

.department-card-optimized:hover:after {
    filter: contrast(1.5) brightness(1.25);
}

.department-card-optimized:hover,
.department-card-optimized:focus {
    transform: perspective(1px) scale(1.05);
}

.department-card-optimized:hover .department-card-optimized__shadow:before,
.department-card-optimized:focus .department-card-optimized__shadow:before {
    opacity: 0.5;
    transform: translateY(25%) scale(1.1);
}

.department-card-optimized__shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.department-card-optimized__shadow:before {
    content: '';
    position: absolute;
    bottom: 10px;
    left: 10%;
    width: 80%;
    height: 10%;
    opacity: 1;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.75);
    transition-duration: .33s;
    transition-property: opacity, transform;
}

.department-card-optimized__title {
    -webkit-font-smoothing: antialiased;
    font-family: var(--font-default-heading-thin);
    z-index: 1;
    position: absolute;
    margin: 0;
    bottom: 7%;
    left: 7%;
}

.department-card-optimized-black__title {
    align-items: flex-start;
    background-color: black;
    color: white;
    display: inline-flex;
    flex-direction: column;
    gap: 15px;
    justify-content: flex-end;
    margin-right: 20px;
    padding: 20px 15px;
}
