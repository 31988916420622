.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: 2em;
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: var(--color-primary);
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-color: var(--color-primary);
}

.wysiwyg table.table {
    border: none !important;
}

.wysiwyg ul li {
    list-style: none;
    position: relative;
    padding-left: 0.75rem;
}

.wysiwyg ul li:before {
    color: var(--color-primary);
    position: absolute;
    content: "\2022";
    font-size: 2rem;
    position: absolute;
    top: -0.75rem;
    left: -1rem;
}

.wysiwyg b,
.wysiwyg strong,
.wysiwyg .strong {
    font-size: 1em;
}