.product-detail__sidebar{
  width: 365px;
}

.product-detail__content{
  width: calc(100% - 365px);
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.product-detail__content .green-label {
    position: absolute;
    right: 0;

    @media screen and (max-width: 1199px) {
        position: relative;
    }
}
.product-detail-gallery{
  margin-bottom: 1.5em;
  transform: translateY(-72px);
  @media screen and (max-width: 1199px) {
    transform: translateY(0);
    flex-direction: column-reverse;
  }
}
.product-detail-gallery__small{
  width: 71px;
  height: 100%;
  @media screen and (max-width: 1199px) {
    width: 100%;
    height: auto;
  }
}
.product-detail-gallery__small .slick-slide{
  @media screen and (max-width: 1199px) {
    margin: 0 5px;
  }
}
.product-detail-gallery__small .slick-list{
  @media screen and (max-width: 1199px) {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.product-detail-gallery__small .slick-track{
  transform: translate3d(0, 0, 0) !important;
  @media screen and (max-width: 1199px) {
    width: 100% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  } 
}
.product-detail-gallery__small-item{
  width: 70px !important;
  height: 70px !important;
  border: 1px solid var(--bg-grey);
  opacity: .5;
  transition-property: opacity;
  transition-duration: .33s;
}


.product-detail-gallery__big{
  width: calc(100% - 70px);
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
}

.product-detail-gallery__corner-item{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 20%;
}

.product-detail-gallery__corner-item .img-fluid{
    width: 75px;
}

.slick-slider .slick-dots, .product-detail-gallery__big .slick-dots{
  list-style: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
  padding-left: 0;
  display: flex;
  justify-content: center;
}
.slick-slider .slick-dots button, .product-detail-gallery__big .slick-dots button{
  font-size: 0;
  margin: 10px;
  border: none;
  background-color: var(--color-text-default);
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.slick-slider .slick-dots .slick-active button, .product-detail-gallery__big .slick-dots .slick-active button {
  background-color: var(--color-primary);
}
.product-detail-gallery__big-item{
  position: relative;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
}

.product-detail-gallery__big-item:after{
  cursor: pointer;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition-duration: .33s;
  transition-property: opacity;
  background-color: rgba(255,255,255,.5);
}
.product-detail-gallery__big-item:hover:after{
  opacity: 1;
  @media screen and (max-width: 767px){
    opacity: 0;
  }
}
.product-detail-gallery__big-item .btn-gallery{
  @media screen and (max-width: 767px){
    display: none;
  }
}

.slick-current .product-detail-gallery__small-item{
  opacity: 1;
}

.product-variant{
  position: relative;
  width: 35px;
  height: 35px;
  margin-right: 6px;
  margin-bottom: 6px;
  border: 1px solid var(--color-lightgrey);
}
.product-variant__small{
  width: 35px;
  height: 35px;
  border: 1px solid var(--color-lightgrey);
}

.product-variant:after, .product-variant:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,0.4);
  opacity: 0;
  transition-duration: .33s;
  transition-property: opacity;
}
.product-variant:hover:after, .product-variant:focus:after {
  opacity: 1;
}
.ribbon-1 {
  padding: 0 10px var(--f) calc(10px + var(--r));
  background: #d8222b;
  color: white;
  text-align: center;
}