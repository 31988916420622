.btn-cartDropdown{
  position: relative;
}

.btn .badge.btn-cartDropdown__badge{
  position: absolute;
  bottom: -5px;
  right: -5px;
  top: auto;
  border-radius: 3px;
  padding-bottom: .5em;
  font-size: .65em;
}
.cart-dropdown{
  width: 350px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.cart-dropdown__products-none{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}
.cart-dropdown .cart-dropdown__carts{
  height: 56px;
  width: 100%;
  transition-duration: .33s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(-50%);
  perspective: 250px;
}
.cart-dropdown.show .cart-dropdown__carts{
  opacity: 1;
  transform: translateY(0%);
}
.cart-dropdown__carts-select, .cart-dropdown__carts-new{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: #fff;
}

.cart-dropdown__products{
  min-height: 75px;
}

.cart-dropdown__product{
  will-change: transform;
  margin: 15px 0;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-duration: .33s;
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateY(-50%);
  display: flex;

}
.cart-dropdown__product:nth-child(1) { transition-delay: .1s }
.cart-dropdown__product:nth-child(2) { transition-delay: .2s }
.cart-dropdown__product:nth-child(3) { transition-delay: .3s }

.cart-dropdown.show .cartDropdown__content.active .cart-dropdown__product{
  opacity: 1;
  transform: translateY(calc(0% - 0.5px));
}


.cart-dropdown__more{
  will-change: transform;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: .3s;
  transition-duration: .5s;
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateY(-50%);
}
.cart-dropdown.show .cartDropdown__content.active .cart-dropdown__more{
  opacity: 1;
  transform: translateY(0%);
}

.cart-dropdown__cart{
  will-change: transform;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-delay: .35s;
  transition-duration: .5s;
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateY(-50%);
}
.cart-dropdown.show .cartDropdown__content.active .cart-dropdown__cart{
  opacity: 1;
  transform: translateY(0%);
}

.js-cartDropdown__cancel{
  transform: translateY(50%);
  opacity: 0;
  transition-delay: .35s;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: opacity, transform;
}
.js-cartDropdown__cancel.show{
  opacity: 1;
  transform: translateY(0);
}

.cart-dropdown__carts-new, .cart-dropdown__carts-select{
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-property: transform;
  transition-duration: .33s;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  z-index: 1;
  transform-origin: center;
  @media all and (-ms-high-contrast:none)
  {
    backface-visibility: visible;
  }
}


.cart-dropdown__carts-new{
  z-index: 0;
  transform: rotateX(180deg);
}

.cart-dropdown__carts.rotated .cart-dropdown__carts-new{
  z-index: 1;
  transform: rotateX(0);
}
.cart-dropdown__carts.rotated .cart-dropdown__carts-select{
  z-index: 0;
  transform: rotateX(180deg);
}

.cartRename__input{
  width: auto;
}

.cartRename__input:disabled{
  background: none;
  border: none;
  box-shadow: none;
  font-weight: 700;
}