.uploader__file-input-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.uploader__file-input {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

