.custom-checkbox .btn-checkbox{
    cursor: pointer;
}
.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}.custom-checkbox:after {
     clear: both;
 }
.custom-checkbox label{
    width: 100%;
}
.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-checkbox__box {
    border-radius: 3px;
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #ffffff;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .1em;
    transition-duration: .15s;
    transition-property: background-color;
}
.custom-checkbox__box:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: calc(7rem/16);
    color: #fff;
    visibility: hidden;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-primary);
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
/*.custom-checkbox__input:checked ~ .custom-checkbox__text{
    color: var(--color-primary);
}*/
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}

.custom-checkbox__input:checked ~ .btn-black-outline {
    color: #fff;
    background-color: #000;
}
.btn-black-outline.btn-checkbox:hover{
    background-color: #fff;
    color: var(--color-primary);
}
.custom-checkbox__input:checked ~ .btn-black-outline.btn-checkbox:hover{
    background-color: #000;
    color: var(--color-primary);
}