.hero-image-wrapper{
  overflow: hidden;
  position: relative;
  height: var(--hero-slider--height);
  @media screen and (max-width:767px){
    height: var(--hero-slider--height--mobile);
  }
}

.hero-image{
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: 100%;
  transform: translate(-50%,-50%);
  @media screen and (max-width: 1920px){
    height: 100%;
    width: auto;
  }
  @media screen and (max-width:767px){
    height: var(--hero-slider--height--mobile);
  }
}

.hero-image-content{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(39, 39, 39, 0.65) 0%, rgba(36,36,36,0) 100%);
}

.hero-slider__img-wrapper--video .pimcore_editable_video{
  flex: 1 1 100%;
  min-width: 0;
  max-width: 1920px;
  height: 100%;
  will-change: contents;
}

.hero-slider__img-wrapper--video .hero-slider__video{
  height: 100%;
  width: 100%;
  @media screen and (max-width:767px){
    height: var(--hero-slider--height--mobile);
  }
}