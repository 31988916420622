.references-area{
    min-height: 360px;
}

.references-content{
    z-index: 1;
    position: relative;
}

.references-text {
    min-height: 130px;
    color: #fff;
    width: 100%;
    @media screen and (min-width: 768px){
        height: 360px;
        background: transparent;
    }
}

.references-background {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 360px;
    background-color: #000;
}
.references-bgimg {
    height: 360px;
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.references-slider{
    @media screen and (min-width: 768px){
        transform: translateY(-50px);
    }
}
.reference-controls{
    @media screen and (min-width: 768px){
        transform: translateY(-30px);
    }
}

.reference-slider-left-absolute, .reference-slider-right-absolute {
    position: absolute;
    top: calc(50% - 100px);
}
.reference-slider-left-absolute {
    left: -75px !important;
}

.reference-slider-right-absolute {
    right: -75px !important;
}