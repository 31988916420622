#history-box {
    position:relative;
    margin-bottom:0;
    padding-bottom:77px;
}


#history-box h2 {
    line-height:100%;
    font-size:303%;
    color:#1d1d1d;
    font-weight: 100;
}
#history-box h2 strong {
    margin:0 -4px;
    line-height:100%;
    font-weight:700;
    font-size:333%;
    vertical-align:-16%;
    background-image:-webkit-gradient(linear,left top,left bottom,from(#46556a),to(#1d1d1d));
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
}
#history-box ol {
    position:relative;
    margin:43px 0 0 0;
    padding:0 80px;
    list-style-type:none;
    background:url(/static/img/history/history-box-ol-bg.png) no-repeat 50% 0;
}
#history-box ol:before {
    content:'';
    display:block;
    position:absolute;
    top:-47px;
    left:170px;
    height:47px;
    width:128px;
    background:url(/static/img/history/history-box-ol-before-bg.png) no-repeat top right;
    box-sizing: unset ;
}
#history-box ol:after {
    content:'';
    display:block;
    position:absolute;
    bottom:-51px;
    left:170px;
    height:93px;
    width:146px;
    background:url(/static/img/history/history-box-ol-after-bg.png) no-repeat top right;
    box-sizing: unset ;
}
#history-box ol.last-element-right:after {
    left:auto;
    right:170px;
    background:url(/static/img/history/history-box-ol-after-bg2.png) no-repeat top right;
    box-sizing: unset ;
}
#history-box ol li {
    position:relative;
    border-top:8px solid var(--bg-yellow);
    margin:0 90px;
    padding:0;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
}
#history-box ol li:first-child { border-top:0; }
#history-box ol li:after {
    content:'';
    display:block;
    border:8px solid var(--bg-yellow);
    position:absolute;
    top:-8px;
    height:100%;
    width:80px;
    box-sizing: unset ;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #history-box ol li:after {
        height: calc(100% + 16px);
    }
}
#history-box ol li:nth-child(odd) { text-align:left; }
#history-box ol li:nth-child(odd):hover span.date, #history-box ol li:nth-child(odd):active span.date, #history-box ol li:nth-child(odd):focus span.date {
    -webkit-animation:history-box-date-bounce 0.75s;
    -moz-animation:history-box-date-bounce 0.75s;
    -o-animation:history-box-date-bounce 0.75s;
    -ms-animation:history-box-date-bounce 0.75s;
    animation:history-box-date-bounce 0.75s;
}
#history-box ol li:nth-child(odd):after {
    border-right:0;
    left:-80px;
    -webkit-border-top-left-radius:80px;
    -moz-border-radius-topleft:80px;
    border-top-left-radius:80px;
    -webkit-border-bottom-left-radius:80px;
    -moz-border-radius-bottomleft:80px;
    border-bottom-left-radius:80px;
}
#history-box ol li:nth-child(odd) span.date {
    margin-right: 10px;
}
#history-box ol li:nth-child(odd) span.date.special { background-position:-750px -700px; }
#history-box ol li:nth-child(odd) span.date.blue { background-position:-900px -700px; }
#history-box ol li:nth-child(odd) h3 { margin-right:18px; }
#history-box ol li:nth-child(odd) h3.arrow { padding-right:40px; }
#history-box ol li:nth-child(odd) h3.arrow:after {
    right:0;
    background-position:-750px -800px;
}
#history-box ol li:nth-child(even) { text-align:right; }
#history-box ol li:nth-child(even):hover span.date, #history-box ol li:nth-child(even):active span.date, #history-box ol li:nth-child(even):focus span.date {
    -webkit-animation:history-box-date-bounce-reverse 0.75s;
    -moz-animation:history-box-date-bounce-reverse 0.75s;
    -o-animation:history-box-date-bounce-reverse 0.75s;
    -ms-animation:history-box-date-bounce-reverse 0.75s;
    animation:history-box-date-bounce-reverse 0.75s;
}
#history-box ol li:nth-child(even):after {
    border-left:0;
    right:-80px;
    -webkit-border-top-right-radius:80px;
    -moz-border-radius-topright:80px;
    border-top-right-radius:80px;
    -webkit-border-bottom-right-radius:80px;
    -moz-border-radius-bottomright:80px;
    border-bottom-right-radius:80px;
}
#history-box ol li:nth-child(even) > div {
    -webkit-box-direction:reverse;
    -moz-box-direction:reverse;
    -webkit-flex-direction:row-reverse;
    -ms-flex-direction:row-reverse;
    flex-direction:row-reverse;
}
#history-box ol li:nth-child(even) span.date {
    margin-left: 10px;
}
#history-box ol li:nth-child(even) span.date.special { background-position:-550px -700px; }
#history-box ol li:nth-child(even) span.date.blue { background-position:-400px -700px; }
#history-box ol li:nth-child(even) h3 { margin-left:18px; }
#history-box ol li:nth-child(even) h3.arrow { padding-left:40px; }
#history-box ol li:nth-child(even) h3.arrow:after {
    left:0;
    background-position:-700px -800px;
}
#history-box ol li > div {
    position:relative;
    margin:0 -170px;
    min-height:160px;
    z-index:2;
    flex-wrap: wrap;
    display:flex;
    flex-direction:row;
    justify-content:flex-start;
    align-content:stretch;
    align-items:flex-start;
}
#history-box ol li > div > * {
    margin-bottom:0;
    -webkit-box-ordinal-group:1;
    -moz-box-ordinal-group:1;
    -webkit-order:0;
    -ms-flex-order:0;
    order:0;
    -webkit-align-self:center;
    -ms-flex-item-align:center;
    align-self:center;
    -webkit-box-flex:0;
    -moz-box-flex:0;
    -webkit-flex:0 0 auto;
    -ms-flex:0 0 auto;
    flex:0 0 auto;
}
#history-box ol li span.date {
    padding:0 25px;
    font-size:260%;
    color: #fff;
    background-color: var(--color-primary);
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,.2);
    text-align:center;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    font-family: var(--font-default-bold);
}
#history-box ol li span.date:after { content:''; }
#history-box ol li h3 {
    position:relative;
    width:160px;
    line-height:150%;
    font-size:120%;
    font-weight:300;
}
#history-box ol li h3.wide {
    width:300px;
    overflow:hidden;
}
#history-box ol li h3.arrow:after {
    content:'';
    display:block;
    position:absolute;
    top:50%;
    margin-top:-26px;
    height:52px;
    width:11px;
    background-image:url(/static/img/images.png);
    background-repeat:no-repeat;
}
#history-box h3{
    font-family: var(--font-default);
}
#history-box ol li h3.dark { color:#2a3038; }
#history-box ol li h3.bold { font-family: var(--font-default-bold);     font-weight: 700; }
#history-box ol li h3.first-line-dark:first-line { color:#2a3038; }
#history-box ol li h3.first-line-bold:first-line { font-family: var(--font-default-bold);     font-weight: 700; }
#history-box ol li h3.first-line-upper:first-line { text-transform:uppercase; }
#history-box ol li .text {
    padding:20px 0;
    width:200px;
    text-align:left;
    -webkit-box-flex:0 1 auto;
    -moz-box-flex:0 1 auto;
    -webkit-flex:0 1 auto;
    -ms-flex:0 1 auto;
    flex:0 1 auto;
    overflow:hidden;
}
#history-box ol li .text img {
    height:auto;
    max-width:100%;
}
#history-box ol.ie li > div {
    display:block;
    line-height:160px;
}
#history-box ol.ie li > div > * {
    display:inline-block;
    line-height:160%;
    vertical-align:middle;
}
#history-box ol.ie li span.date { line-height:52px !important; }
#history-box ol.ie li .text { width:280px; }
body[data-lang="2"] #history-box h2 { font-size:290%; }

@media only screen and (min-width: 800px) and (max-width: 1024px) {
    #history-box ol {
        margin-top: 60px;
    }

    #history-box h2 {
        font-size: 200%;
    }

    #history-box ol li h3 {
        width: auto;
    }
}

@media only screen and (max-width: 800px) {
    #history-box ol {
        margin-top: 60px;
    }

    #history-box ol:before {
        display: none;
    }

    #history-box h2 {
        font-size: 200%;
    }

    #history-box ol li > div {
        min-height: 300px;
    }

    #history-box ol li h3 {
        position: absolute;
        top: 10px;
        left: 166px;
        width: auto;
    }

    #history-box ol li:nth-child(2n) h3 {
        right: 166px;
        left: auto;
    }

    #history-box ol li h3.arrow:after {
        display: none;
    }

    #history-box ol li h3.wide {
        width: auto;
    }

    #history-box ol li .text {
        padding-top: 100px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    #history-box ol {
        padding: 0 50px;
    }

    #history-box ol.last-element-right:before, #history-box ol.last-element-right:after {
        background: none;
    }

    #history-box ol li {
        border: 0;
    }

    #history-box ol li:after {
        border: 0;
    }
}


@media only screen and (max-width: 320px) {
    #history-box ol {
        padding: 0 50px;
    }

    #history-box ol.last-element-right:before, #history-box ol.last-element-right:after {
        background: none;
    }

    #history-box ol li {
        border: 0;
    }

    #history-box ol li:after {
        border: 0;
    }
}

@keyframes history-box-date-bounce {
    40%, 70%, 100% {transform: translateX(0);}
    55% {transform: translateX(-30%);}
    85% {transform: translateX(-10%);}
}

@keyframes history-box-date-bounce-reverse {
    40%, 70%, 100% {transform: translateX(0);}
    55% {transform: translateX(30%);}
    85% {transform: translateX(10%);}
}

#history-box ol li:nth-child(2n) .historie__content{
    flex-direction: row-reverse;
}

.historie__history{
    padding: 20px 0;
}

#history-box ol li > div .text p {
    padding: 1.25rem;
    background-color: #fff;
}

#history-box .historie__history img{
    padding: 1.25rem;
}

@media only screen and (max-width: 480px) {
    #history-box ol li > div .text p, #history-box .historie__history img{
        padding: 0.25rem;
    }
    #history-box ol li:nth-child(2n)>div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    #history-box ol li:nth-child(2n) h3{
        right: initial;
    }
    #history-box ol li > div {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    #history-box ol li > div  h3{
        margin-top: 25px;
        position: relative;
        top: initial;
        left: initial;
        right: initial;
        max-width: 300px;
        text-align: center;
    }
    #history-box ol li > div .text{
        padding-top: 0.25rem;
    }

    #history-box .historie__history{
        padding: 0;
    }
    #history-box .date{
        margin-top: 50px;
    }
}