.mixing-formulations__wrapper{
    min-height: 175px;
}

.mixing-formulations__item{
    cursor: pointer;
    position: relative;
    transition-duration: .33s;
}
.mixing-formulations__item:after{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.2);
    transition-property: opacity;
    transition-duration: .33s;
    pointer-events: none;
}

.mixing-formulations__item:hover:after{
    opacity: 1;
}

.mixing-formulations__calculator{
    border: 1px solid var(--color-lightgrey);
    transition-property: background-color;
    background-color: transparent;
    transition-duration: .33s;
}

.table--mixing-formulations{
    width: 100%;
}
.table--mixing-formulations thead{
    background-color: var(--bg-yellow);
    box-shadow: inset 0px 15px 12px -10px rgba(49, 49, 49, 0.2);
}
.table--mixing-formulations thead th{
     padding: .5rem;
}
.table--mixing-formulations td{
    padding: 1.25rem .5rem;
}

.mixing-formulations__calculator-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.mixing-formulations__name{
    transition-property: transform;
}

.mixing-formulations__close{
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 0;
        right: 0;
    }
}