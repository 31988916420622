body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: var(--font-size-default);
}
.text-bigger{
    font-size: 1.2em;
}
b,
strong,
.strong {
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: 0.9em;
}
.bold{
    font-family: var(--font-default-heading-thin);
}
a.link, .link{
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-color: var(--bg-yellow);
}
a.hover-link:hover, .hover-link:hover, a.hover-link:focus, .hover-link:focus{
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-color: var(--bg-yellow);
}
a.hover-link--text{
    color: var(--color-primary)
}
a.hover-link--text:hover, .hover-link--text:hover, a.hover-link--text:focus, .hover-link--text:focus{
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-color: var(--color-primary);
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: var(--h1);
    line-height: 1.28;
    @media screen and (max-width: 767px) {
        font-size: var(--h1-mobile);
        line-height: 1.166;
    }
}
h2, .h2 {
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: var(--h2);
    line-height: 1.166;
    @media screen and (max-width: 767px) {
        font-size: var(--h2-mobile);
        line-height: 1.4;
    }
}
h3, .h3 {
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: var(--h3);
    line-height: 1.3;
    @media screen and (max-width: 767px) {
        font-size: var(--h3-mobile);
        line-height: 1.4;
    }
}
h4, .h4 {
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: var(--h4);
    line-height: 1.444;
    @media screen and (max-width: 767px) {
        font-size: var(--h4-mobile);
        line-height: 1.4;
    }
}
h5, .h5 {
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: var(--h5);
}
h6, .h6 {
    font-size: var(--h6);
}

p, .p{
    font-family: TheMixTT3;
    font-size: var(--p);
    line-height: 1.5;
    text-decoration-color: var(--bg-yellow);
}

.palanquinDarkSemiBold, .text-heading{
    font-family: var(--font-default-heading);
    font-weight: bold;
    font-size: 0.9em;
}

small, .small{
    font-size: var(--small)
}
.smaller{
    font-size: .75em;
}
.supersmall{
    font-size: .65em;
}
.icon-sm{
    font-size: .5em;
}
.icon-lg{
    font-size: 1.5em;
}

.heading-stripe{
    position: relative;
}
.heading-stripe:after{
    content: '';
    position: absolute;
    left: 0;
    top: -32px;
    width: 264px;
    height: 2px;
    background-color: var(--bg-yellow);
}
.heading-stripe--small:after{
    top: -16px;
    width: 112px;
    height: 3px;
}
.font-default {
    font-family: var(--font-default);
}
.flex-1{
    flex: 1 0 0px;
    flex-basis: auto;
}