
.language-links a{
  border-left: 1px solid black;
  padding: 0 10px;
}
.language-link:hover .language-links a:last-child{
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}
.language-link:hover .language-links a:hover{
  font-weight: bold;
  color: #fff;
  text-decoration: underline;
}
.language-link:hover .language-links a:hover ~ a:last-child{
  text-decoration: none !important;
}
.language-links a:first-child{
  border-left: none;
}
.language-link{
  transition-duration: .33s;
  transition-property: background-color, color;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.language-link:hover{
  color: #fff;
  background-color: var(--color-primary);
}