.content-anchor-menu{
  z-index: 999;
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: 1525px) {
    bottom: 0;
    top: auto;
    transform: translateX(0);
  }
}

.content-anchor-menu ul{
  @media screen and (max-width: 1525px) {
    display: flex;
  }
}

.content-anchor-menu__item{
  margin-bottom: 1px;
  @media screen and (max-width: 1525px) {
    margin-bottom: 0;
    margin-left: 1px;
  }
}

.content-anchor-menu__item a{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 1.5em;
  color: #fff;
  background-color: var(--color-primary);

}
.content-anchor-menu__item:last-child{
  margin-bottom: 0;
}