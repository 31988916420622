.parallax-img{
    position: relative;
}

.parallax-text--container{
    will-change: transform;
    width: 300px;
    position: absolute;
    top: 0;
    transform: translateY(42%);
    color: white;
    background-color: #F9C623;
    z-index: 1;
    transition-duration: .33s;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    @media screen and (max-width: 575px){
        transform: translateY(73%);
        width: 250px;
    }
    @media screen and (max-width: 767px){
        width: 210px;
    }
    @media screen and (max-width: 991px){
        width: 250px;
    }
}

.parallax-text--container.parallax-text--container-left{
    right: 16px;
    @media screen and (max-width: 767px){
        right: 0;
    }
}

.parallax-text--container.parallax-text--container-right{
    left: 16px;
    background-color: black;

    @media screen and (max-width: 767px){
        left: 0;
    }
}

.parallax-text{
    padding: 35px 37px;
    height: 307px;
    @media screen and (max-width: 991px){
        height: auto;
        padding: 27px 24px;
    }
    @media screen and (max-width: 1199px){
        height: 250px;
    }
}

.parallax-img__headerText{
    background-color: black;
    color: white;
    position: absolute;
    top: 23px;
    z-index: 1;
    text-transform: uppercase;
}

.parallax-img__headerText.parallax-img__headerText--left{
    right: 0;
}

.parallax-img__headerText.parallax-img__headerText--right{
    left: 0px;
}

.parallax-text__main-text{
    max-height: 130px;
    overflow: hidden;

    @media screen and (max-width: 767px){
        height: 100px;

    }
}
