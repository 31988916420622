.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);
    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}

.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}




.loading-container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
}
.loading-container--overlay:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.5)
}

.product-grid__loading svg, .loading-svg{
    z-index: 1;
    overflow: visible;
    position: absolute;
    position: sticky;
    top: calc(50% - 40px);
    left: calc(50% - 60px);
}
.loading-svg--absolute{
    position: absolute !important;
}
.product-grid__loading svg{
    position: sticky;
}
.d-spinner {
    animation-duration: 1.5s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    transform-origin: left bottom;
    transition-duration: .33s;
    fill: #D8222B;
}
.d-spinner.d-spinner__one {
    animation-name: dSpinnerOne;
}
.d-spinner.d-spinner__two {
    animation-name: dSpinnerTwo;
}
.d-spinner.d-spinner__three {
    animation-name: dSpinnerThree;
}
@keyframes dSpinnerOne {
    0% {
        opacity: 0;
        transform: rotateZ(-65deg) scale(0.6);
    }
    17% {
        transform: rotateZ(0) scale(1);
    }
    57% {
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }
    60%{
        opacity: 1;
    }
    74% {
        opacity: 1;
        transform: rotateZ(0) scale(1);
        animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }
    83% {
        opacity: 0;
        transform: rotateZ(45deg) scale(0.61);
    }
    100% {
        opacity: 0;
        transform: rotateZ(45deg) scale(0.61);
    }
}
@keyframes dSpinnerTwo {
    0% {
        opacity: 0;
        transform: rotateZ(-65deg) scale(0.6);
    }
    25% {
        transform: rotateZ(0) scale(1);
    }
    60% {
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }
    65% {
        opacity: 1;
    }
    71% {
        opacity: 1;
        transform: rotateZ(0) scale(1);
        animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }
    79% {
        opacity: 0;
        transform: rotateZ(45deg) scale(0.61);
    }
    100% {
        opacity: 0;
        transform: rotateZ(45deg) scale(0.61);
    }
}
@keyframes dSpinnerThree {
    0% {
        opacity: 0;
        transform: rotateZ(-65deg) scale(0.6);
    }
    38% {

        transform: rotateZ(0) scale(1);
    }
    63% {
        animation-timing-function: cubic-bezier(0, 0, 0, 1);
    }
    68% {
        opacity: 1;
        transform: rotateZ(0) scale(1);
        animation-timing-function: cubic-bezier(0, 0, 1, 0);
    }
    76% {
        opacity: 0;
        transform: rotateZ(45deg) scale(0.61);
    }
    100% {
        opacity: 0;
        transform: rotateZ(45deg) scale(0.61);
    }
}