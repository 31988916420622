.bg-masonry-fixed{
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;

  padding-top: 35px;

  @media screen and (max-width: 767px) {
    padding-top: 35px;
  }
}





.grid-home{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  grid-auto-rows: minmax(250px, auto);
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(150px,auto);
  }
}
.grid-item-small{
  grid-column: 1 span;
  grid-row: 1 span;
}
.grid-item-big{
  grid-column: 1 span;
  grid-row: 2 span / span 3;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid-item:nth-child(2n){
    -ms-grid-column: 2;
  }
  .grid-item:nth-child(3){
    -ms-grid-row: 2;
  }
  .grid-item:nth-child(4){
    -ms-grid-row: 3
  }
  .grid-item:nth-child(5){
    -ms-grid-row: 4
  }
  .grid-item:nth-child(6){
    -ms-grid-row: 5
  }
  .grid-item:nth-child(7){
    -ms-grid-row: 6
  }
  .grid-item:nth-child(8){
    -ms-grid-row: 7
  }
}
@supports (-ms-ime-align: auto) {
  .grid-item:nth-child(1){
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

