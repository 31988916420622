@import 'bootstrap-select/dist/css/bootstrap-select.css';

.js-selectpicker{
    display: none;
}

.bootstrap-select .dropdown-item.active, .bootstrap-select .dropdown-item:active{
    background-color: #f8f9fa;
    color: var(--color-primary);
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn){
    max-width: 100%;
}

.bootstrap-select .dropdown-toggle{
    border: none;
    background: #fff;
    padding: 0.25rem .75rem;
}

.bootstrap-select .dropdown-toggle:after{
    border: none;
    content: url('/static/img/icons/arrow-small.svg');
    width: 10px;
    height: 24px;
}

.bootstrap-select .dropdown-toggle,.bootstrap-select .dropdown-toggle p {
    font-family: var(--font-default-heading-thin);
}

.bootstrap-select--lg .dropdown-toggle{
    padding: .85rem;
}