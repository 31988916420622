.modal-fs {
  max-width: none;
  height: 100%;
  margin: 0;
  padding: 1.75rem;
}
.modal-fs .modal-content {
  height: 100%;
  background-color: rgba(255,255,255,.75);
}

.modal-content{
  border-radius: 5px;
  box-shadow: 0 2px 50px 0 rgba(0,0,0,0.2);
}
.modal-header{
  border-bottom: none;
  justify-content: center;
}
.modal-header .close{
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.modal-footer{
  border-top: none;
}

.modal-dialog-scrollable{
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal--color-search .modal-lg{
  @media screen and (min-width: 768px) and (max-width: 991px){
    max-width: 80vw;
  }
  @media screen and (max-width: 767px) {
    max-width: 100vw;
  }
}

.modal--corner-bl {
    pointer-events: none;
}

.modal--corner-bl .modal-dialog {
    position: absolute;
    bottom: 1rem;
    right: 0;
    margin: 0 1rem;
}