.scrollable-style::-webkit-scrollbar{
    width: 10px;
    height: 10px;
    background-color: var(--color-lightgrey);
}
.scrollable-style::-webkit-scrollbar-track{
    background-color: var(--color-lightgrey);
}
.scrollable-style::-webkit-scrollbar-thumb{
    background-color: var(--color-grey);
}