.register-filter{
    overflow-y: auto;
    display: flex;
    padding-bottom: 1rem;
}

.register-filter__link{
    font-size: 30px;
    font-family: var(--font-default-bold);
    position: relative;
    margin: 0 .25rem;
    padding: 0 .25rem;
    transition-duration: .15s;
    transition-property: color;
    user-select: none;
}

.register-filter__link--disabled{
    color: var(--color-muted);
}

.register-filter__link:not(.register-filter__link--disabled):hover{
    color: var(--color-primary);

}

.register-filter__link--active{
    color: var(--color-primary);
}
.register-filter__link--active:after{
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    background-color: var(--color-primary);
}