/*Margin Helper*/
.container.container {
    /*max-width: 91rem;*/
}

/*Padding Helper*/
.container--mobile-padding {
    @media screen and(max-width: 576px) {
        padding: 0;
    }
}

/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*Text Color Helper*/
.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.text-yellow {
    color: var(--bg-yellow);
}

.text-color-default {
    color: var(--color-text-default);
}


/*Background Color Helper*/
.bg-grey {
    background-color: var(--bg-grey);
}

.bg-white {
    background-color: #fff;
}

.bg-yellow {
    background-color: var(--bg-yellow);
}

.bg-black {
    background-color: black;
}

.js-go-to-link {
    cursor: pointer;
}

.icon-arrow-left {
    transform: rotate(180deg);
    padding: 0 5px;
}

.z-index-0 {
    z-index: 0;
}

.z-index-1 {
    z-index: 1;
}

@media screen and (min-width: 768px) {
    .w-md-auto {
        width: auto !important;
    }

    .w-md-100 {
        width: 100% !important;
    }
}

@media screen and (min-width: 992px) {
    .w-lg-100 {
        width: 100% !important;
    }
}

.relative {
    position: relative;
}

.border-grey--left {
    border-left: 1px solid #9B9B9B;
}

.border-grey--right {
    border-right: 1px solid #979797;
}

.list-unstyled {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

.user-noselect {
    user-select: none;
}

.user-noevents {
    pointer-events: none;
}

.overflow-hidden {
    overflow: hidden;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.min-w-100 {
    min-width: 100%;
}

@media screen and (min-width: 1600px) {
    .d-xxxl-none {
        display: none !important;
    }

    .d-xxxl-block {
        display: block !important;
    }
}

.anchor-target {
    pointer-events: none;
    margin-top: -100px;
    padding-top: 100px
}

.list-inline li {
    padding: .25rem .5rem;
    border-right: 1px solid var(--bg-grey)
}

.list-inline li:last-child {
    border-right: none
}

.box-shadow {
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
}

.box-shadow-2:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .5;
    box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .2);
    transition-property: opacity;
    transition-duration: .33s;
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer;
}

.border--transparent {
    border-color: transparent !important;
}

.badge.badge--yellow {
    background-color: var(--bg-yellow);
    color: #fff;
}

.line-height-1 {
    line-height: 1;
}


.lg-outer .lg {
    @media screen and (min-width: 992px) {
        width: 65% !important;
        height: 65% !important;
        min-width: 700px;
        min-height: 500px;
        overflow: visible;
    }

    background: white;
}

.lg-outer.lg-visible {
    display: flex;
    align-items: center;
}

.lg-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.lg-toolbar.lg-group {
    background-color: inherit;
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

.lg-outer .lg-item {
    background-color: transparent;
}

.lg-toolbar .lg-icon:hover {
    color: var(--color-primary);
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
    background-color: transparent;
    color: #999;
}

.lg-actions .lg-next:hover,
.lg-actions .lg-prev:hover {
    color: var(--color-primary);
}

.lg-object.lg-image {
    padding-top: 47px;
    padding-bottom: 47px;
}

.lg-sub-html {
    color: #999;
    position: absolute;
    background-color: #fff;
    @media screen and (min-width: 992px) {
        top: 90%; /* magic number, dont change :D */
        bottom: auto;
    }
}

.order-by .dropdown-toggle {
    position: relative;
    width: 100%;
    min-width: 165px !important;
}

.cseImage {
    max-height: 150px;
    max-width: 150px;
    background-size: cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

img.img100x100 {
    max-width: 100px;
    max-height: 100px;
    height: 100px;
    width: 100px;
}

.applicationWizardSmallText {
    font-size: 12px;
}

.overflow-hidden{
    overflow: hidden;
    position: relative;
    height: 100%;
}

.floating-label.floating-label--active-cart{
    font-size:1.2rem;
}

.dotted-border{
    border: 2px dashed var(--color-grey);
    border-radius: 0.5rem;
}

.custom-tooltip {
    position: relative;
    padding-right: 1rem;
}

.custom-tooltip .close {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    cursor: pointer;
}

.modal-open{overflow:hidden !important;}

/** disable google maps fullscreen button on ios due to errors */
@supports (-webkit-overflow-scrolling: touch) {
    .gm-fullscreen-control {
        display: none;
    }
}