.ean-scanner {
    height: calc(100vh - var(--header-height));
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 991px){
        height: calc(100vh - var(--header-height-mobile));
    }
}
.ean-scanner__header {
    display: flex;
    flex: none;
    z-index: 1;
    position: relative;
}

.ean-scanner__body {
    position: relative;
    flex: auto;
}

.ean-scanner__canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background: #000;
}


.ean-scanner__canvas video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.ean-scanner__overlay {
    position: absolute;
    top: 30%;
    left: 0%;
    right: 0%;
    bottom: 30%;
    text-align: center;
}

.ean-scanner__overlay--border-top-left {
    position: absolute;
    top: 30%;
    left: 0%;
    border-top: 3px solid white;
    border-left: 3px solid white;
    height: 50px;
    width: 50px;
}

.ean-scanner__overlay--border-top-right {
    position: absolute;
    top: 30%;
    right: 0%;
    border-top: 3px solid white;
    border-right: 3px solid white;
    height: 50px;
    width: 50px;
}

.ean-scanner__overlay--border-bottom-right {
    position: absolute;
    right: 0%;
    bottom: 30%;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    height: 50px;
    width: 50px;
}

.ean-scanner__overlay--border-bottom-left {
    position: absolute;
    left: 0%;
    bottom: 30%;
    border-bottom: 3px solid white;
    border-left: 3px solid white;
    height: 50px;
    width: 50px;
}

.has-detected-code .ean-scanner__overlay {
    box-shadow: inset 0 0 0 1vmin #d8222b;
}

.ean-scanner__overlay:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    width: 1px;
    background: #fff;
    animation: animateScan 2s infinite;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier(0.11, 0.01, 0.88, 0.99);
}
.has-detected-code .ean-scanner__overlay:before,
.is-paused .ean-scanner__overlay:before {
    animation-play-state: paused;
}


@keyframes animateScan {
    0% {
        transform: translateX(0)
    }
    100% {
        transform: translateX(100vmin)
    }
}

.ean-scanner__overlay:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 999px rgba(0, 0, 0, 0.4);
}

.ean-scanner__overlay-title {
    position: absolute;
    bottom: 100%;
    margin-bottom: 1em;
    width: 100%;
    z-index: 1;
    color: #fff;
    font-family: var(--font-default-bold);
}
.ean-scanner__overlay-toggle-btn {
    position: absolute;
    top: 110%;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
}


.ean-scanner__modal__close {
    font-size: 2rem;
    line-height: .5;
}


.ean-scanner__modal .modal-body {
    padding: 1.5rem;
}
