.form-inline{
    flex-grow: 1;
    max-width: 280px;

    @media screen and (max-width: 1300px){
        max-width: 205px;
    }
}

.form-inline.main-search--mobile{
    max-width: 100%;
}

.form-inline .main-search__input {
    min-width: 100%;
    height: 55px;
    border: 1px solid #9B9B9B;
    border-radius: 36px;
    font-size: 14px;
}
.form-inline .main-search__input::-ms-clear{
    display: none;
}

/* clears the 'X' from Chrome */
.main-search__input::-webkit-search-decoration,
.main-search__input::-webkit-search-cancel-button,
.main-search__input::-webkit-search-results-button,
.main-search__input::-webkit-search-results-decoration { display: none; }

.main-search{
    position: relative;
    flex: 1 0 auto;
    align-items: center;
}

.main-search__icon{
    font-size: 25px;
    position: absolute;
    right: 27px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.js-searchbar-placeholder{
    z-index: -1;
}

.js-header-toggler .twitter-typeahead{
    background-color: white;
    width: 100%;
}

.main-search--mobile .twitter-typeahead{
    width: 100%;
}
.main-search--mobile .twitter-typeahead input{
    background-color: white !important;
}

.tt-hint{
    display: none !important;
}

.tt-menu{
    width: 100%;
    background-color: #fff;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.tt-suggestion{
    margin-bottom: 1rem;
}