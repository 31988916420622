.typeahead{

}

.typeahead .tt-menu{
    position: relative;
    margin: 0 25px;
    width: calc(100% - 60px);
    background-color: #fff;
    color: #000;
    transform: translateY(-1px);
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.typeahead .tt-menu:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .15;
    transition-duration: .33s;
    transition-property: opacity;
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,.5);
    z-index: -1;
    pointer-events: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.typeahead .tt-selectable:hover,
.typeahead .tt-selectable.tt-cursor {
    cursor: pointer;
    /*background: #f0f0f2;*/
    color: var(--color-primary);
    text-decoration: underline;
}

.typeahead .typeahead-heading{
    padding: 5px 0 5px 10px;
    border-bottom: 1px solid #dfe1e6;
    margin-bottom: 5px;
    color: var(--color-primary);
}

.typeahead .tt-suggestion {
    padding: 2px 0 2px 25px;
    font-size: 0.9rem
}

.typeahead-heading__link{
    font-family: iconfont;
    padding-right: 10px;
}

.typeahead-heading__link:before{
    content: "\EA06";
    padding-right: 5px;
    font-size: 12px;
}