@font-face {
    font-family: "iconfont";
    src: url('/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/static/build/icons/font/iconfont.woff') format('woff'),
    url('/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-adler-icon:before { content: "\EA02" }
.icon-arrow-big:before { content: "\EA03" }
.icon-arrow-left:before { content: "\EA04" }
.icon-arrow-right:before { content: "\EA05" }
.icon-arrow-small:before { content: "\EA06" }
.icon-arrow:before { content: "\EA07" }
.icon-back:before { content: "\EA08" }
.icon-barcode-scan:before { content: "\EA09" }
.icon-calendar:before { content: "\EA0A" }
.icon-cart-add-download:before { content: "\EA0B" }
.icon-cart-no:before { content: "\EA0C" }
.icon-cart:before { content: "\EA0D" }
.icon-check-content:before { content: "\EA0E" }
.icon-check:before { content: "\EA0F" }
.icon-circle:before { content: "\EA10" }
.icon-close:before { content: "\EA11" }
.icon-delete:before { content: "\EA12" }
.icon-diy:before { content: "\EA13" }
.icon-download-remove:before { content: "\EA14" }
.icon-download:before { content: "\EA15" }
.icon-duplicate:before { content: "\EA16" }
.icon-edit:before { content: "\EA17" }
.icon-elements:before { content: "\EA18" }
.icon-external-link:before { content: "\EA19" }
.icon-facebook:before { content: "\EA1A" }
.icon-fax:before { content: "\EA1B" }
.icon-globe:before { content: "\EA1C" }
.icon-google-plus:before { content: "\EA1D" }
.icon-handwerk:before { content: "\EA1E" }
.icon-heart-active:before { content: "\EA1F" }
.icon-heart:before { content: "\EA20" }
.icon-industry:before { content: "\EA21" }
.icon-insta:before { content: "\EA22" }
.icon-linkedin:before { content: "\EA23" }
.icon-list-rec:before { content: "\EA24" }
.icon-list:before { content: "\EA25" }
.icon-mail:before { content: "\EA26" }
.icon-menu-mobile:before { content: "\EA27" }
.icon-minus:before { content: "\EA28" }
.icon-newsletter:before { content: "\EA29" }
.icon-percent:before { content: "\EA2A" }
.icon-phone:before { content: "\EA2B" }
.icon-pinterest:before { content: "\EA2C" }
.icon-play-button:before { content: "\EA2D" }
.icon-plus:before { content: "\EA2E" }
.icon-print:before { content: "\EA2F" }
.icon-search:before { content: "\EA30" }
.icon-shop:before { content: "\EA31" }
.icon-twitter:before { content: "\EA32" }
.icon-user:before { content: "\EA33" }
.icon-warning:before { content: "\EA34" }
.icon-whatsapp:before { content: "\EA35" }
.icon-xing:before { content: "\EA36" }
.icon-xingLogoOnly:before { content: "\EA37" }
.icon-youtube:before { content: "\EA38" }
.icon-youtubeLogoOnly:before { content: "\EA39" }


:root {
--icon-addthis: "\EA01";
    --icon-adler-icon: "\EA02";
    --icon-arrow-big: "\EA03";
    --icon-arrow-left: "\EA04";
    --icon-arrow-right: "\EA05";
    --icon-arrow-small: "\EA06";
    --icon-arrow: "\EA07";
    --icon-back: "\EA08";
    --icon-barcode-scan: "\EA09";
    --icon-calendar: "\EA0A";
    --icon-cart-add-download: "\EA0B";
    --icon-cart-no: "\EA0C";
    --icon-cart: "\EA0D";
    --icon-check-content: "\EA0E";
    --icon-check: "\EA0F";
    --icon-circle: "\EA10";
    --icon-close: "\EA11";
    --icon-delete: "\EA12";
    --icon-diy: "\EA13";
    --icon-download-remove: "\EA14";
    --icon-download: "\EA15";
    --icon-duplicate: "\EA16";
    --icon-edit: "\EA17";
    --icon-elements: "\EA18";
    --icon-external-link: "\EA19";
    --icon-facebook: "\EA1A";
    --icon-fax: "\EA1B";
    --icon-globe: "\EA1C";
    --icon-google-plus: "\EA1D";
    --icon-handwerk: "\EA1E";
    --icon-heart-active: "\EA1F";
    --icon-heart: "\EA20";
    --icon-industry: "\EA21";
    --icon-insta: "\EA22";
    --icon-linkedin: "\EA23";
    --icon-list-rec: "\EA24";
    --icon-list: "\EA25";
    --icon-mail: "\EA26";
    --icon-menu-mobile: "\EA27";
    --icon-minus: "\EA28";
    --icon-newsletter: "\EA29";
    --icon-percent: "\EA2A";
    --icon-phone: "\EA2B";
    --icon-pinterest: "\EA2C";
    --icon-play-button: "\EA2D";
    --icon-plus: "\EA2E";
    --icon-print: "\EA2F";
    --icon-search: "\EA30";
    --icon-shop: "\EA31";
    --icon-twitter: "\EA32";
    --icon-user: "\EA33";
    --icon-warning: "\EA34";
    --icon-whatsapp: "\EA35";
    --icon-xing: "\EA36";
    --icon-xingLogoOnly: "\EA37";
    --icon-youtube: "\EA38";
    --icon-youtubeLogoOnly: "\EA39";
    
}