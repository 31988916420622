#referencesFormColorSpaceContainer {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    box-shadow: inset 0 1px 3px 0 rgb(0 0 0 / 24%);
}
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element {
    position: relative;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    margin-right: 5px;
    border: 1px solid var(--color-lightgrey);
    cursor: pointer;
}
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element img {
    width: 100%;
    height: 100%;
}
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element:after,
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.4);
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
}
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element:hover:after,
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element:focus:after,
#referencesFormColorSpaceContainer .color-chooser .color-chooser-element.active {
    opacity: 1;
}