@import '@elements/floating-labels/floating-label.css';
.floating-label{
  top: 28px;
}
.floating-label, select~.floating-label,
.floating-label:has(+ select){
  transform: translateY(-40%);
}
.form-control.has-value~.floating-label, .form-control:focus~.floating-label,
.floating-label:has(+ .form-control.has-value), .floating-label:has(+ .form-control:focus) {
  transform: translateY(-66%) scale(.55);
}
.form-control.js-floating-label, .form-control.floating-label-input{
  padding-top: 25px;
  height: calc(2.25rem + 20px);
}

.form-control{
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.24);
}
.form-control.form-control--50{
  width: 50px;
}
.form-control.form-control--100{
  width: 100px;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

.form-group--no-margin .form-group {
  margin-bottom: 0;
}
.form-group--help-right {
    margin-right: 1.75rem;
}
.form-group--help-right .form-group__help {
    right: -1.25rem;
    padding: 0.3rem 0.45rem .3rem .4rem;
    font-size: 0.5rem;
}
.form-group__help {
  position: absolute;
  top: -.5rem;
  right: -.5rem;
  background: #d8222b;
  border-radius: 50%;
  padding: .1rem .55rem .25rem;
  color: #fff;
  font-family: PalanquinDarkSemiBold;
  font-size: 1.05rem;
  line-height: 1;
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  cursor: default;
  user-select: none;
}
.form-group--help-left .form-group__help {
  right: auto;
  left: -0.75rem;
}
.form-group__help:hover {
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);
}
select{
  -webkit-appearance:none;
  -moz-appearance: none;
}
select::-ms-expand { 
  display: none;
}
.select-custom{
  position: relative;
}
.select-custom select{
  position: relative;
}
.select-custom__icon{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 10px;
  top: 0;
  height: 56px;
  width: 10px;
  font-size: .35em;
  color: var(--color-primary);
  pointer-events: none;
}
.select-custom__icon:after{
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  height: 100%;
  width: 15px;
  pointer-events: none;
}
.select-custom.select-custom--noborder select{
  border: none;
  box-shadow: none;
  padding: 0;
}
.select-custom.select-custom--noborder {
  padding: 0 .75rem;
}
.select-custom.select-custom--noborder .select-custom__icon{
  background: #fff;
}
.select-custom.select-custom--noborder .select-custom__icon:after{
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
}

.form-control:focus{
  box-shadow: none;
}

select.select--yellow,
select.select--yellow:focus{
  background-color: var(--bg-yellow);
  box-shadow: none;
  border: none;
}
select.select--yellow ~ .select-custom__icon{
  background-color: var(--bg-yellow);
}
select.select--yellow ~ .select-custom__icon:after{
  background: var(--bg-yellow);
}

select.select--yellow option{
  background-color: #fff;
}


.parsley__form-errors-small .parsley-errors-list{
  padding: 0;
  color: var(--color-danger);
  list-style: none;
}

.parsley-errors-list{
  padding: 0;
  color: var(--color-danger);
  list-style: none;
}

.select--product-type .filter-option{
  font-family: var(--font-default);
}

.select--product-type{
    min-width: 0;
}

.form-group.has-error input, .form-group.has-error select{
  box-shadow: inset 0 0px 0px 1px var(--color-danger)
}
.form-group.has-error .floating-label{
  color: var(--color-danger)
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea.form-control{
    position: relative;
    top: 0;
    transform: translate(0);
}

textarea ~ .floating-label,
.floating-label:has(+ textarea){
    pointer-events: none;
    position: absolute;
    transform: translate3d(0, 0, 0) scale(1);
    transform-origin: left top;
    transition: 100ms;
    top: 10px;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label,
.floating-label:has(+ textarea.form-control.has-value),
.floating-label:has(+ textarea.form-control:focus){
    transform: translateY(-0%) scale(.65);
}

.card-header.card-header--inline {
  background: #fff;
  border: none;
  position: absolute;
  top: -0.8rem;
  left: 0.5rem;
  padding: 0 0.5rem;
}

.card-header.card-header--inline .card-title {
  font-size: 1rem;
}

.form-errors .parsley-errors-list.filled {
  background: var(--color-primary);
  color: white;
  padding: 0.5rem 1rem;
}

.drag-drop-container {
  border: 2px solid transparent;
}

.drag-drop-container.dragging {
  border: 2px dashed var(--color-grey);
  border-radius: 0.5rem;
}
