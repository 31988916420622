.kpi-box{
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.1);
    padding: .75em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.kpi-box__title{
    color: var(--color-grey);
    font-family: var(--font-default-bold)
}

.kpi-box__text{
    font-size: 2.5em;
    margin-bottom: 0;
    text-align: right;
    @media screen and (max-width: 1199px) {
        font-size: 2em;
    }
}

.kpi-box--big .kpi-box__text{
    font-size: 1em;
}