.content-text-image > div:first-child > div{
  float: left;
}
.content-text-image > div:last-child > div{
  float: right;
}
.content-text-image > div.order-md-2 > div{
  float: right;
}
.content-text-image > div.order-md-1  > div{
  float: left;
}

.content-text-image__image{
  width: 110%;
  height: 100%;
  z-index: 1;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.content-text-image__image--aside{
  width: 100%;
}

.content-text-image__text{
  z-index: 2;
  margin-top: 50px;
  background-color: #000;
  color: #fff;
  width: 110%;
  min-height: calc(100% - 50px);
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 0;
    min-height: auto;
  }
}

.content-text-image__text a{
    color: var(--bg-yellow);
}

.content-text-image__text--aside {
  width: 100%;
  min-height: 100%;
  margin-top: 0;
}