.sidebar{
  clear: both;
}

.sidebar-container{
  padding: 15px;
  border: 1px solid var(--bg-grey);
  margin-bottom: 3px;
  background-color: #fff;
}

.sidebar-container:last-child{
    margin-bottom: 0 !important;
}

.sidebar-container--shadow{
  border: none;
  box-shadow: 2px 2px 50px -20px rgba(0,0,0,.3);
  margin-bottom: 6px;
}

.sidebar-contactlist > li+li{
  margin-top: 26px;
  padding-top: 26px;
  border-top: 1px solid var(--bg-grey);
}

.sidebar--imgbg{
  position: relative;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.sidebar-factslist > li+li{
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--bg-grey);
}
.sidebar-productlist > li+li{
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--bg-grey);
}
.list--borders > li+li{
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid var(--bg-grey);
}
.sidebar-img-text__image{
    width: calc(100% + 30px);
    max-width: calc(100% + 30px);
    height: auto;
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
}