#checkout-accordion {
    border: 1px solid var(--color-lightgrey)
}

.custom-radio--checkout-accordion{
    border-bottom: 1px solid var(--color-lightgrey)
}
.custom-radio--checkout-accordion .custom-radio__box{
    width: 2rem;
    height: 2rem;
}

.checkout-collapse{
    border-bottom: 1px solid var(--color-lightgrey)
}

.checkout__cart-error{
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(255,255,255,0.6);
}

.table--checkout .parsley-errors-list.filled{
    z-index: 1;
    position: absolute;
    left: -75px;
    top: calc(100% + 8px);
    width: 250px;
    background-color: #fff;
    border: 1px solid var(--color-danger);
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    list-style: none;
    padding: .75rem;
    @media screen and (max-width: 767px) {
        right: 0;
        left: auto;
    }
}
.table--checkout .parsley-errors-list.filled:after{
    position: absolute;
    content: '';
    top: -8px;
    left: 90px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 8px 6px;
    border-color: transparent transparent var(--color-danger) transparent;
    @media screen and (max-width: 767px) {
        right: 21px;
        left: auto;
    }
}

.checkout__modal-location{
    border-bottom: 1px solid var(--color-lightgrey);
    margin-top: 1rem;
    padding-bottom: 1rem;
}


.checkout__modal-location:last-child{
    border-bottom: none
}

.checkout__delete-address-button {
    position: absolute;
    top: 0.5rem;
    right: 0;
    padding-top: 7px;
}