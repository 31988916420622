.slider-content-full__text {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    padding: 1.25rem 1.5rem;
    text-shadow: 1px 2px 13px rgba(0,0,0,.3);
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.slider-content-full__image{
    background: var(--bg-yellow);
}