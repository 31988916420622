html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
    -webkit-overflow-scrolling: touch;
}
body {
    position: relative;
}

@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
/*
.container.container {
    max-width: calc(1220rem/16);
    width: 100%;
}
*/

.app-container.app-container--header{
    padding-top: var(--header-height);
    @media screen and (max-width: 991px){
        padding-top: var(--header-height-mobile);
    }
}

.embed-responsive-cover{
    height: 100%;
}
.embed-responsive-hero-slider:before{
    padding-top: 42.361%;
}
.embed-responsive-hero-slider:has(.pimcore_editable_video_progress):before{
    padding-top: 0;
}
.embed-responsive-text-img-slider:before{
    padding-top: percentage(27 / 23);
    @media screen and (max-width: 767px){
        padding-top: percentage(1 / 1);
    }
}
.embed-responsive-text-img-slider:has(.pimcore_editable_video_progress):before{
    padding-top: 0;
    @media screen and (max-width: 767px){
        padding-top: 0;
    }
}
.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
.embed-responsive-3by2 {
    &::before {
        padding-top: percentage(2 / 3);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}
.embed-responsive-3by4 {
    &::before {
        padding-top: percentage(4 / 3);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}

.embed-responsive-4by3 {
    &::before {
        padding-top: percentage(3 / 4);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}
.embed-responsive-16by9 {
    &::before {
        padding-top: percentage(9 / 16);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}

.embed-responsive-94by35 {
    &::before {
        padding-top: percentage(35 / 94);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}

.embed-responsive-masonry {
    &::before {
        padding-top: percentage(106 / 91);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }

    @media screen and (max-width: 576pc){
        &::before {
            padding-top: percentage(1 / 1);
        }
        &:has(.pimcore_editable_video_progress)::before {
            padding-top: 0;
        }
    }
}
.embed-responsive-1by1 {
    &::before {
        padding-top: percentage(1 / 1);
    }
    &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}.embed-responsive-7by5 {
    &::before {
        padding-top: percentage(5 / 7);
    }
     &:has(.pimcore_editable_video_progress)::before {
        padding-top: 0;
    }
}

.page-container{
    padding-bottom: 75px;
}

.page-container--bg{
    position: relative;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 100%;
}
