.tiles-container .tile:nth-child(2n) .tile__textarea, .tiles-container .tile-wrapper:nth-child(2n) .tile .tile__textarea{
    background-color: black;
    color: #fff;
}
.tiles-container .tile:nth-child(2n) .tile__textarea .hover-link--text, .tiles-container .tile-wrapper:nth-child(2n) .tile .tile__textarea .hover-link--text{
    color: #fff;
}

/*------------Tiles------------*/
.tile {
    position: relative;
    display: block;
    will-change: transform;
    margin-bottom: 5rem ;
}
a.tile .tile__img:after {
    transition-duration: .33s;
    transition-property: filter;
}
a.tile:hover .tile__img:before, a.tile:active .tile__img:before, a.tile:focus .tile__img:before {
    opacity: 1;
}
a.tile:hover .tile__img:after, a.tile:active .tile__img:after, a.tile:focus .tile__img:after {
    filter: contrast(1.5) brightness(1.25);
}
a.tile:hover .tile__textarea, a.tile:active .tile__textarea, a.tile:focus .tile__textarea{
    transform: perspective(1px) scale(1.05);
}
.tile__img {
    position: relative;
}
.tile__img:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.33);
}
.tile__img-overlay{
    z-index: 1;
    position: absolute;
    top: 15px;
    right: 0;
    background-color: #000;
    color: #fff;
    height: 33px;
    display: flex;
    align-items: center;
    padding: 5px;
}

.tile__textarea {
    z-index: 1;
    transform-origin: bottom right;
    transition-duration: .33s;
    transition-property: transform;
    min-height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    background-color: var(--bg-yellow);
    position: absolute;
    bottom: -70px;
    right: -15px;
    max-width: 245px;
    @media screen and (max-width: 1199px){
        max-width: 200px;
    }
}

.bigTile__text-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-gradient:after {
    width: 100%;
    height: 100%;
    content:'';
    background: linear-gradient(59deg, #ee4756 1%,#f9c623 100%);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    pointer-events: none;
}
.img-gradient-2:after {
    width: 100%;
    height: 100%;
    content:'';
    background: linear-gradient(59deg, #ee4756 1%,#f9c623 100%);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.75;
    pointer-events: none;
}

.video-gradient{
    position: relative;
}
.video-gradient:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background: linear-gradient(to right, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 5%,rgba(0,0,0,0) 95%,rgba(0,0,0,1) 100%);
}

.text-img-content__wrapper {
    min-height: 500px;
}

.text-img-content__img-wrapper{
    position: relative;
}

.text-img-content__img{
    position: relative;

    @media screen and (min-width: 768px){
        position: absolute;
        left: 0;
        top: -50px;
    }
}


.tile.tile--asset{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.tile.tile--asset .tile__img:hover .lightbox-open{
    opacity: 1;
}
.tile.tile--asset .tile__img{
    max-width: 100%;
    cursor: pointer;
}
.tile.tile--asset .tile__img:after{
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
}
.tile.tile--asset .tile__img:hover:after{
    opacity: .33;
}
.tile.tile--asset div.tile__textarea{
    bottom: 25px;
    color: #000;
    background-color: #fff;
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.1);
    position: relative;
}
.tile.tile--no-text-overlay {
    margin-bottom: 0;
}
.tile.tile--no-text-overlay:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.33);
}
.tile.tile--no-text-overlay .tile__textarea {
    position: relative;
    bottom: auto;
    right: auto;
    max-width: 100%;
    min-height: auto;
}
a.tile.tile--no-text-overlay:hover .tile__textarea, a.tile.tile--no-text-overlay:active .tile__textarea, a.tile.tile--no-text-overlay:focus .tile__textarea{
    transform: perspective(1px);
}
a.tile.tile--no-text-overlay:hover:before, a.tile.tile--no-text-overlay:active:before, a.tile.tile--no-text-overlay:focus:before {
    opacity: 1;
}

.tile__img {
    position: relative;
}
.tile__img:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    box-shadow: 0 0 40px 0 rgba(0,0,0,.33);
}

.lightbox-open{
    pointer-events: none;
    color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition-property: opacity;
    transition-duration: .33s;
}
.lightbox-open:after {
    width: 100%;
    height: 100%;
    content:'';
    background: linear-gradient(59deg, #ee4756 1%,#f9c623 100%);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.33;
    pointer-events: none;
}
.lightbox-open i{
    z-index: 1;
}