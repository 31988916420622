.wishlist__btn.is-active .icon:after {
    position: absolute;
    top: 0;
    left: 0;
    content: url('/static/img/icons/heart-active.svg')
}
.wishlist__btn.is-active .icon:before {
    opacity: 0;
}
.wishlist__remove{
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 25px;
}
.wishlist__remove:hover {
    color: var(--color-primary)
}

.download-cart__btn .icon:before, .download-cart__btn .icon:after{
    display:inline-block;
}
.download-cart__btn.is-active .icon:before{
    opacity: 0;
}
.download-cart__btn.is-active .icon:after{
    position: absolute;
    top: -3px;
    left: 0;
    content: url('/static/img/icons/download-remove.svg')
}


.download-cart__count{
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 2px);
    border-radius: 3px;
    padding-bottom: .5em;
    font-size: .5em;
    opacity: 0;
}

.download-cart__count.is-active{
    opacity: 1;
    animation: 0.5s wiggle ease;
    animation-iteration-count: 1;
}

@keyframes wiggle{
    20%  { transform: translateX(4px);  }
    40%  { transform: translateX(-4px); }
    60%  { transform: translateX(2px);  }
    80%  { transform: translateX(-1px); }
    100% { transform: translateX(0);    }
}