.tips-n-tricks_steps__list{
  counter-reset: my-awesome-counter;
  list-style: none;
  position: relative;
  padding-left: 0;
}
.tips-n-tricks_steps__img{
  @media screen and (min-width: 768px) {
    min-width: 215px;
    width: 215px;
    height: 215px;
  }
}
.tips-n-tricks_steps__list li.tips-n-tricks_steps__has-text{
  counter-increment: my-awesome-counter;
}

.tips-n-tricks_steps__text{
  width: 100%;
  position: relative;
}
.tips-n-tricks_steps__text:before{
  font-family: var(--font-default-heading);
  font-size: calc(150rem / 16);
  content: counter(my-awesome-counter);
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.1;
  line-height: 0.5;

  @media screen and (max-width: 767px) {
    font-size: calc(125rem / 16);
    height: auto;
    top: 0;
  }

}