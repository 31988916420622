@import 'dropzone/dist/basic.css';
@import 'dropzone/dist/dropzone.css';

.form-group:has(> .dynamic-multi-file) {
    border: 2px dashed var(--color-grey);
    border-radius: 0.5rem;
}

.form-group:has(> .dynamic-multi-file) .floating-label {
    top: 10px;
    transform: translateY(0);
}

.form-group:has(> .dynamic-multi-file) .dropzone {
    min-height: auto;
    border-color: transparent;
    padding: 30px 0;
}

.was-validated .form-group:has(> .dynamic-multi-file):not(*:has(.is-invalid)) {
    border-color: #28a745;
}

.form-group:has(> .dynamic-multi-file .is-invalid) {
    border-color: #dc3545;
}

.form-group:has(> .dynamic-multi-file) .invalid-feedback {
    margin-left: .25rem;
    margin-bottom: .25rem;
}