.breadcrumb{
  background-color: transparent;
  font-size: calc(15em/16);
}

.breadcrumb-item+.breadcrumb-item:before{
  content: '>';
  color: var(--color-muted);
}

.breadcrumb-item{
  color: var(--color-muted);
}
.breadcrumb-item.active{
  color: var(--color-text-default);
}
.breadcrumb-item.active:before{
  color: var(--color-text-default);
}