@media screen and (max-width: 576px){
    .footer-col--heading{
        font-size: 14px;
        cursor: pointer;
    }
}

.footer--icon{
    font-size: 26px;
}

.footer-ul{
    font-family: TheMixTT3;
}

.dropdown-open{
    transform: rotate(180deg);
}