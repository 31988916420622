

/* Global variables and settings */
@import './style/variables.pcss';
@import './style/icons.css';
@import './style/mediaqueries.pcss';


/* General base styling & reset (mostly plain element styling; very low specificity ) */
@import './style/base.pcss';
@import './style/base-layout.pcss';
@import './style/base-typo.pcss';


/* Generic helper classes (mostly layout) */
@import './style/row--gutter.pcss';


/* Components */
@import './style/custom-checkbox.pcss';
@import './style/custom-radio.pcss';
@import './style/custom-select.pcss';
@import './style/wishlist.pcss';

@import './style/loading-spinner.pcss';

@import './style/form-errors.pcss';
@import './style/content-block.pcss';
@import './style/wysiwyg.pcss';
@import './style/shariff.pcss';
@import './style/header.pcss';
@import './style/footer.pcss';
@import './style/searchbar.pcss';
@import './style/parallax.pcss';
@import './style/table.pcss';
@import './style/cards.pcss';
@import './style/masonry.pcss';
@import './style/breadcrumb.pcss';
@import './style/buttons.pcss';
@import './style/sidebar.pcss';
@import './style/formelements.pcss';
@import './style/form_builder.pcss';
@import './style/flag.pcss';
@import './style/footer-finder.pcss';
@import './style/cart.pcss';
@import './style/video.pcss';
@import './style/productgrid.pcss';
@import './style/product-detail.pcss';
@import './style/tabs.pcss';
@import './style/list.pcss';
@import './style/references.pcss';
@import './style/on-page-nav.pcss';
@import './style/typeahead.pcss';
@import './style/checkout.pcss';
@import './style/accordion.pcss';
@import './style/mixing-formulations.pcss';
@import './style/locations.pcss';
@import './style/video-card.pcss';
@import './style/register-filter.pcss';
@import './style/scrollbar.pcss';
@import './style/kpi.pcss';
@import './style/application-wizard.pcss';
@import './style/history.css';
@import './style/iziToast.pcss';
@import './style/uploader.pcss';
@import './style/ean-scanner.pcss';
@import './style/alert.pcss';
@import './style/play-button.pcss';
@import './style/reference-search.pcss';


@import './style/content/contact-form.pcss';
@import './style/content/tips-n-tricks.pcss';
@import './style/content/content-text-image.pcss';
@import './style/content/content-anchor-menu.pcss';
@import './style/slider/content-slider.pcss';
@import './style/slider/contentImgSlider.pcss';
@import './style/slider/slider-content-full.pcss';


@import './style/modals/modal.pcss';
@import './style/modals/modal-language.pcss';


/* SLIDER */
@import './style/slider/slider.pcss';
@import './style/slider/slide.pcss';
@import './style/slider/department-slider.pcss';
@import './style/slider/departmentSliderOptimied.pcss';
@import './style/slider/heroSlider.pcss';

@import './style/hero-image.pcss';


/* Utility & Helper Classes (mostly atomic classes; high specificity; eg: mt10; fz20)*/
@import './style/oocss.pcss';


@import './style/base-loaded.pcss';

@import './style/popupBox.pcss';

.header-dropdown-menu{
    visibility: visible;
}
.header-dropdown-container{
    opacity: 1;
    visibility: visible;
}



