.wizard__steps{

}
.wizard-steps__step{
    border-bottom: 5px solid transparent;
    transition-duration: .33s;
    transition-property: border-bottom-color;
    min-height: 87px;
    @media screen and (max-width: 767px){
        min-height: 25px;
    }
}
a.wizard-steps__step:hover{
    border-bottom-color: var(--bg-yellow);
}
.wizard-steps__step > div{
    border-right: 1px solid var(--bg-yellow);
    margin-right: 0 !important;
    @media screen and (max-width: 767px){
        border-right: none;
    }
}
.wizard-steps__step:last-child > div{
    border-right: none;
    margin-right: 1rem !important;
}

.wizard-steps__step.is-active{
    background-color: var(--bg-yellow);
    position: relative;
}
.wizard-steps__step.is-active:after{
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 1px;
    height: calc(100% + 5px);
    background-color: var(--bg-yellow);
    z-index: -1;
}
.wizard-steps__step.is-disabled{
    opacity: 0.75;
    cursor: not-allowed;
}

.wizard-card{
    display: block;
    position: relative;
}
.wizard-card:hover:after{
    opacity: 1;
}
.wizard-card__text{
    transition-property: background-color, color;
    transition-duration: .33s;
}
.wizard-card:hover .wizard-card__text{
    background-color: #000;
    color: #fff
}