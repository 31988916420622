
.video-card{
    position: relative;
    overflow: visible;
    transition-duration: .33s;
    transition-property: transform;
}

.video-card:after{
    transition-duration: .33s;
    transition-property: filter;
}
.video-card:hover:after{
    filter: contrast(1.5) brightness(1.25);
}
.video-card:hover, .video-card:focus{
    transform: perspective(1px) scale(1.05);
}
.video-card:hover .video-card__shadow:before, .video-card:focus .video-card__shadow:before{
    opacity: 0.5;
    transform: translateY(25%) scale(1.1)
}
.video-card__shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
.video-card__shadow:before{
    content: '';
    position: absolute;
    bottom: 10px;
    left: 10%;
    width: 80%;
    height: 10%;
    opacity: 1;
    box-shadow: 0 20px 50px 0 rgba(0,0,0,.75);
    transition-duration: .33s;
    transition-property: opacity, transform;
}

.video-card__title{
    -webkit-font-smoothing: antialiased;
    font-family: var(--font-default-heading-thin);
    z-index: 1;
    position: absolute;
    margin: 0;
    bottom: 7%;
    left: 7%;
}
.video-card__btn{
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    color: #fff;
    z-index: 10;
    transition-duration: .33s;
}
.video-card:hover .video-card__btn{
    transform: scale(1.2);
}