
.product-variant-morelink {
    width: 50%;
    color: var(--bg-primary);
    text-align: left;
    cursor: pointer;
}

@media screen and (max-width: 767px) {

    .product-detail-first-box {
        margin-bottom: 24px;
    }

    .product-detail-gallery__small {
        display: none;
    }

    .product-detail-gallery__big {
        flex-direction: column;
        width: 100%;

        .slick-list {
            width: 100%;
        }
    }

    .product-detail-gallery__big .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .product-detail-gallery__big-item {
        width: 100%;
    }

    .product-detail-downloads {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
    .product-detail-downloads .list-item li {
        flex-direction: column;
        align-items: flex-start;
    }

    .product-detail__content .green-label {
        display: none;
    }
    .nav-tabs--product-detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .nav-tabs--product-detail .nav-item {
        flex: 0 0 calc(50% - 8px);
        margin-bottom: 16px;
        text-align: center;
        padding: 0 16px;
    }
    .nav-tabs--product-detail .nav-item a {
        display: block;
        width: 100%;
    }
    .nav-tabs--product-detail .nav-item:nth-child(3) {
        flex: 0 0 auto;
        margin: 0 auto 16px auto;
        text-align: center;
    }

    .product-detail-pictograms-fertility-area {
        display: block;

        strong {
            display: block;
        }
    }
}

.product-detail-first-box {
    margin-bottom: 40px;
}

.product-detail-downloads {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

.product-detail-downloads .list-item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 0 !important;
}
.product-detail-downloads .list-item i {
    margin-right: 16px;
}

.product-detail__content{
    @media screen and (max-width: 767px) {
    }
}

.product-detail__content .green-label {
    margin-left: 20px;
    padding: 20px 0px;
    position: absolute;
    left: 75%;
}

.product-detail-gallery {
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-detail-gallery__big {
    width: 380px;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-detail-gallery__small {
    width: 380px;
    margin-top: 64px;
}

.product-detail-gallery__small-item {
    width: 90px !important;
    height: 90px !important;
    border: 1px solid var(--bg-grey);
    opacity: .5;
    transition-property: opacity;
    transition-duration: .33s;
}

.slick-track .slick-slide {
    margin-right: 8px;
}

.slick-current .product-detail-gallery__small-item {
    opacity: 1;
}

.slick-slider .slick-dots,
.product-detail-gallery__big .slick-dots {
    list-style: none;
    padding-left: 0;
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);

    display: flex;
    justify-content: center;
}

.slick-slider .slick-dots button,
.product-detail-gallery__big .slick-dots button {
    font-size: 0;
    margin: 10px;
    border: none;
    background-color: var(--color-text-default);
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.slick-slider .slick-dots .slick-active button,
.product-detail-gallery__big .slick-dots .slick-active button {
    background-color: var(--color-primary);
}

.product-detail-gallery__big-item {
    position: relative;
    cursor: pointer;
    display: flex !important;
    justify-content: center;
}

.product-detail-gallery__big-item:after {
    cursor: pointer;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
    background-color: rgba(255, 255, 255, .5);
}
/*
.product-detail-gallery__big-item:hover:after {
    opacity: 1;
    @media screen and (max-width: 767px) {
        opacity: 0;
    }
}
*/

.product-detail-gallery__big-item .btn-gallery {
    @media screen and (max-width: 767px) {
        display: none;
    }
}

.product-detail-gallery__corner-item {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    left: 20%;
}

.product-detail-gallery__corner-item .img-fluid {
    width: 75px;
}

.product-detail-gallery-box {
}

.product-detail-header-box {

    .sidebar-container {
        margin-bottom: 24px !important;
    }
}

.product-detail-pictograms,
.product-detail-fertility {
    margin: 32px 0 !important;
}

.product-detail-pictograms {
    strong {
        vertical-align: top;
    }

    picture, img {
        width: auto;
        height: 42px;
    }
}

.product-detail-buy-box {
    margin-bottom: 24px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px #00000026, 0px 1px 4px 0px #00000026;

    .bootstrap-select{
        border: 1px solid #929292;
    }
}

.product-detail-b2c-box {
    margin-bottom: 24px;
    padding: 15px;
    background-color: #F4F4F4;
    display: flex;
    gap: 15px;

    .product-detail-b2c-box__text {
    }

    .product-detail-b2c-box__title {
        display: block;
    }

    .product-detail-b2c-box__description {
        display: block;
    }

    .product-detail-b2c-box__link {
    }
}

.product-detail-pictograms-fertility-area {
    display: flex;

    strong {
        vertical-align: top;
    }
}

.product-detail-variant-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0 30px;
}

.product-variant{
    position: relative;
    width: 35px;
    height: 35px;
    margin-right: 6px;
    margin-bottom: 6px;
    border: 1px solid var(--color-lightgrey);
}
.product-variant__small{
    width: 35px;
    height: 35px;
    border: 1px solid var(--color-lightgrey);
}

.variant-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    .product-variant,
    .product-variant__small {
        width: 42px;
        height: 42px;
        margin: unset;
    }

    .product-variant__small {
        @media screen and (max-width: 767px) {
            width: 48px;
            height: 48px;
        }
    }
}

.product-variant:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.4);
    opacity: 0;
    transition-duration: .33s;
    transition-property: opacity;
}
.product-variant:hover:after, .product-variant:focus:after {
    opacity: 1;
}

.product-variant::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1.5px solid #929292;
}
.product-variant--active::before{
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border: 1.5px solid #28191A;
}

.product-variant--active::after{
    box-shadow: none;
}

.ribbon-1 {
    padding: 0 10px var(--f) calc(10px + var(--r));
    background: #d8222b;
    color: white;
    text-align: center;
}

#product-detail-tabcontent-certificates {
    & table.table td:nth-child(1) > img {
        height: auto;
    }
}

@media screen and (max-width: 767px) {

    .product-detail-variant-filters {

        .product-variant-color-or-parent {
            flex-basis: 100%;
        }

        .product-variant-gloss-level {
            flex-basis: 100%;
        }

        .product-variant-size {
            flex-basis: 100%;
        }

        .product-variant-size-description {
            flex-basis: 100%;
        }
    }

    .product-detail-b2c-box {
        flex-direction: column;

        .product-detail-b2c-box__text {
        }

        .product-detail-b2c-box__title {
        }

        .product-detail-b2c-box__description {
        }

        .product-detail-b2c-box__link {
            width: 100%;
        }
    }

    #product-detail-tabcontent-certificates {
        & table.table td:nth-child(1) > img {
            max-width: 4.125rem;
        }
    }

    .product-detail-gallery-lightbox {
        .lg-toolbar {
            bottom: 0;
            top: auto;
            width: 50%;
            left: auto;
            right: 0;
        }

        .lg-actions {
            position: absolute;
            left: 50%;
            right: 50px;
            bottom: 3px;
            display: flex;
            justify-content: flex-end;
            z-index: 1500;
        }

        .lg-actions .lg-next, .lg-actions .lg-prev {
            position: static;
        }

        .lg-sub-html {
            width: 50%;
            text-align: left;
        }
    }
}

@media screen and (min-width: 768px) {

    .product-detail-variant-filters {

        .product-variant-color-or-parent {
            flex-basis: 100%;
        }

        .product-variant-gloss-level {
            flex-basis: calc(50% - 15px);
        }

        .product-variant-size {
            flex-basis: calc(50% - 15px);
        }

        .product-variant-size-description {
            flex-basis: calc(50% - 15px);
        }
    }

    .product-detail-b2c-box {
        align-items: flex-end;

        .product-detail-b2c-box__text {
            flex: 1;
            min-width: 0;
        }

        .product-detail-b2c-box__title {
        }

        .product-detail-b2c-box__description {
        }

        .product-detail-b2c-box__link {
            max-width: 50%;
        }
    }

    #product-detail-tabcontent-certificates {
        & table.table td:nth-child(1) > img {
            max-width: clamp(4.125rem, 100%, 14.75rem);
        }
    }
}

@media screen and (max-width: 991px) {
    .product-detail-second-box {
        .product-detail-contentgrid {
            flex: 1;
            min-width: 0;
        }
    }

    .product-detail-contentgrid .product-accessories,
    .product-detail-contentgrid .product-slider {
        max-width: 540px;
        margin: 0 auto;
    }

    .product-detail-contentgrid .product-accessories {
        display: block;
    }

    .product-detail__sidebar .product-accessories {
        display: none;
    }

    .product-detail__sidebar {
        max-width: 540px;
        margin: 0 auto;
    }
}

@media screen and (min-width: 992px) {
    .product-detail-first-box {
        display: flex;
        gap: 48px;
        container: container-product-detail-first-box / inline-size;

        @container container-product-detail-first-box (max-width: 1111px) {
            .product-detail-gallery-box {
                width: 380px;
            }

            .product-detail-header-box {
                flex: 1;
                min-width: 0;
            }
        }

        @container container-product-detail-first-box (min-width: 1112px) {
            .product-detail-gallery-box {
                flex: 1;
                min-width: 0;
            }

            .product-detail-header-box {
                width: 722px;
            }
        }
    }

    .product-detail-second-box {
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;

        .product-detail-contentgrid {
            flex: 1;
            min-width: 0;
        }
    }

    .product-detail-contentgrid .product-accessories {
        display: none;
    }

    .product-detail__sidebar .product-accessories {
        display: block;
    }

    .product-detail__sidebar {
        width: 365px;
    }
}
