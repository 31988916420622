.card--accordion .card-header{
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none !important;
    padding-left: .75em;
}

.card--accordion .card-header .icon{
    transition-duration: .33s;
    transition-property: transform;
}
.card--accordion .card-header:not(.collapsed) .icon{
    transform: rotate(180deg);
}


.accordion--zertificates{
    border: 1px solid var(--color-lightgrey);
}


.accordion--zertificates .card--accordion{
    border: none;
}
.accordion--zertificates .card-header{
    border: 3px solid transparent;
    border-bottom: none;
}
.accordion--zertificates .card-header:not(.collapsed){
    border: 3px solid var(--bg-yellow);
    border-bottom: none;
}
.accordion--zertificates .accordion__content{
    border: 3px solid var(--bg-yellow);
    border-top: none;
}

.bootstrap-select--lg .dropdown-menu{
    min-width: 100% !important;
}

.accordion--striped .card--accordion:nth-child(odd){
    background-color: var(--color-lightgrey);
}