.content-slider{
  position: relative;
}
.content-slider .slick-track{
  padding: 25px 0;
} 
.content-slider .slick-slide{
  transition-duration: .5s;
  transform: scale(.8);
  transform-origin: 100% 50%;
  position: relative;
}
.content-slider .embed-responsive{
  overflow: visible;
}
.content-slider .slick-slide .embed-responsive:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-duration: .33s;
  transition-property: opacity;
  box-shadow: 0 2px 40px 0 rgba(0,0,0,0.3);
  opacity: 0.5;
}
.content-slider .slick-slide.slick-center .embed-responsive:after{
  opacity: 1;
  z-index: -1;
}


.content-slider .slick-slide.slick-center{
  transform: scale(1)
}

.content-slider .slick-slide.slick-center + .slick-slide{
  transform-origin: 0 50%;
}
.content-slider .halfCircle{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.content-slider .halfCircle--left{
  left: 0;
}
.content-slider .halfCircle--right{
  right: 0
}