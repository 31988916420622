.onPageNav{
    width: 100%;
    position: fixed;
    z-index: 100;
}

.onPageNav__spacer{
    @media screen and (max-width: 767px) {
        height: 40px;
    }
}

.onPageNav .onPageNav__container{
    max-height: calc(75vh - var(--header-height-mobile));
    overflow-y: auto;
    background-color: #fff;
    box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.5);
}