.popup-box__container {
    background-color: white;
    box-shadow: 0 2px 50px 0 rgba(0,0,0,.2);

    position: fixed;
    left: 0;
    bottom: 0;
    margin: 16px;
    z-index: 5;

    border: 1px solid rgba(0,0,0,.2);

    @media screen and (max-width: 574px){
        right: 0;
    }
}

.popup-box__content {
    position: relative;
}

.popup-box__link {
    display: flex;

    text-decoration: none;
    color: black
}

.popup-box__border-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.popup-box__border-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.popup-box__image-container {
    display: flex;
    align-items: center;
}

.popup-box__text {
    padding: 20px 50px 20px 20px;

    width: 300px;
    height: 200px;

    overflow: hidden;

    @media screen and (max-width: 574px){
        width: 100%;
    }
}

.popup-box__button {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1;

    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;

    border: 0;
    border-radius: 5px;
    opacity: 0.5;
    background-color: transparent;
    cursor: pointer;
}

.popup-box__button:hover {
    opacity: 1.0;
}

