.locations__list-item{
    margin-bottom: 1rem;
    display: block;
}

.locations__list-item span{
    transition-duration: .33s;
    transition-property: border-color;
    border-bottom: 2px solid transparent;
}
.locations__list-item:hover span{
    border-color: var(--color-primary);
}
.locations__list-item--active span{
    border-color: var(--color-primary);
}

.locations__location{
    background: #fff;
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,0.1);
}