.header-container{
    pointer-events: none;
    min-height: var(--header-height);
    @media screen and (max-width: 991px){
        min-height: var(--header-height-mobile);
    }
}

.navbar-primary{
    background-color: var(--bg-primary);
}

.header__bottomElement{
    min-height: var(--header-height-main);
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    background-color: white;
    pointer-events: auto;

    @media screen and (max-width: 991px){
        min-height: var(--header-height-mobile);
    }
}
.header__bottomElement.transformed,
.js-search--mobile-wrapper.transformed{
    transform: translateY(-45px);
    transition-duration: 0.3s;
}

.header__bottomElement.transformed{
    background-color: white;
}

.header__bottomElement__shopping-dropdown.show i{
    color: var(--color-primary)
}
.header__bottomElement__user-dropdown i, .header__bottomElement__shopping-dropdown i{
    transition-duration: .33s;
}
.header__bottomElement__user-dropdown.show .login-arrow{
    transform: rotate(180deg)
}
.login-arrow{
    font-size: calc(6em/16);
    color: black;
}

.language-arrow{
    font-size: calc(14em/16);
    transform: rotate(90deg);
}

.navbar--top-ul{
    font-size: calc(15em/16);
}

.mail-phone--icon{
    font-size: calc(24em/16);
}
.navbar--dropdown{
    position: absolute;
    z-index: 1;
}

.user-profile--icon{
    font-size: calc(28em/16);
}

.main-search__wrapper{
    position: relative;

    @media screen and (min-width: 992px){
        height: var(--header-height-main) !important;
    }

    @media screen and (max-width: 991px){
        position: fixed;
        top: var(--header-height-mobile);
        left: 0;
        z-index: 100;
        width: 100%;
        background-color: white;
        height: calc(100% - var(--header-height-mobile));
    }
}


.header__topElement{
    font-family: TheMixTT3;
    font-size: calc(16em/16);
    min-height: var(--header-height-metabar);
    pointer-events: auto;
}
.nav-dropdown-menu{
    color: red;
}

.header__dropdown-button{
    border-radius: 0;
    height: 75px;
    width: 75px;
}

.header-logo{
    width: 177px;
    height: 55px;

    @media screen and (max-width: 767px){
        width: 114px;
        height: 36px;
    }
}

.header-icon{
    font-size: calc(25em/16);
}

.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    left: 0;
    right: 0;
}

.subnav-items{
    line-height: 1.2em;
}
.subnav-items a:hover, .subnav-items a:active, .subnav-items a:focus{
    text-decoration: underline;
    text-decoration-skip: ink;
    text-decoration-color: var(--bg-yellow);
}

.subnav--height{
    @media screen and (max-width: 991px){
        max-height: 100%;
        min-height: 100%;
        padding-bottom: 50px;
        overflow: auto;
        background-color: white;
        position: fixed;
    }
}

.header-subnav{
    position: absolute;
    left: 0;
    z-index: 100;

    @media screen and (min-width: 992px){
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);
    }

    top: var(--header-height-main);
    @media screen and (max-width: 992px){
        top: var(--header-height-mobile);

    }
    font-family: TheMixTT3;
}

.header-subnav__boxes .department-card{
  max-height: 300px;
}

.js-close-subnav{
    @media screen and (max-width: 767px){
        cursor: pointer;
    }
}

.subnav__items{
    cursor: pointer;
    font-family: var(--font-default-heading);
    font-size: 1.06em;
    @media screen and (max-width: 991px){
        text-transform: uppercase;
        font-size: var(--h2-mobile);
    }
}

.subnav__items.active{
    border-bottom: 4px solid #F9C623;
}

.user-profile__icon{
    font-size: calc(27em/16);
}

.header__login-bottom{
    font-family: var(--font-default-heading);
}

.header__login-top, .header__login-bottom{
    line-height: 1.3;
    font-size: calc(14em/16);
}

.btn--login:hover .text-danger,
.btn--login:active .text-danger,
.btn--login:focus .text-danger{
    color: #bd2130 !important;
}

.header-dropdown-container{
    position: relative;
    height: var(--header-height-main);
    align-items: center;
}

.header-dropdown-menu{
    border: none;
    margin: 0;
}


.js-search--mobile-wrapper{
    pointer-events: all;
}

