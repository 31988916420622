.list-bottom-border > li{
  border-bottom: 1px solid var(--bg-grey)
}
.list-bottom-border > li:last-child{
  border-bottom: none
}

.list-checks{
  list-style-image: url('/static/img/icons/check-content.svg');
}
.list-active{
  font-family: var(--font-default-heading);
  font-weight: normal;
  font-size: 1.1em;
}